import { Colors, Fonts } from '@styles';
import { CONTENT_CONTAINER_WIDTH, MAX_WIDTH_MOBILE } from '@utils';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${Colors.white};

  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99;

  position: fixed;
`;

export const HeaderContainer = styled.header`
  max-width: ${CONTENT_CONTAINER_WIDTH}px;
  height: 60px;
  padding: 0px 10px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    height: 55px;
    justify-content: center;
  }
`;

export const LogoTextContainer = styled.div`
  flex: 1;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    flex: none;
  }
`;

export const LogoText = styled.img`
  width: 107px;
  height: 18px;
`;

export const Buttons = styled.div`
  column-gap: 44px;

  display: flex;
  flex-direction: row;
`;

interface HeaderButtonProps {
  color: string;
  active?: boolean;
}
export const HeaderButton = styled.button<HeaderButtonProps>`
  ${(props) => (props.active ? Fonts.Bold : Fonts.Medium)};
  ${Fonts.body2};
  color: ${(props) => (props.active ? Colors.blue400 : props.color)};
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  height: 30px;
  padding: 0px 5px;
  background-color: transparent;
  cursor: pointer;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: none;
  }
`;

export const MenuButton = styled.button`
  display: none;
  position: absolute;
  margin: none;
  background-color: transparent;
  border: none;
  left: 15px;
  cursor: pointer;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: block;
  }
`;

export const MenuIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export const Margin = styled.div`
  height: 60px;
  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    height: 55px;
  }
`;
