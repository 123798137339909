export const Home = {
  HOME_STORE_BUTTON_SECTION_HEAD3: '서울대학교병원 정신건강의학과 전문의와 함께 개발한',
  HOME_STORE_BUTTON_SECTION_HEAD1: ['정신건강을 위한 첫걸음,', '닥터리슨과 함께 하세요.'],

  DO_NOT_WORRY_SECTION_HEADS: ['나, 왜이러는 걸까!', '혼자 막막하신가요?'],
  DO_NOT_WORRY_SECTION_CIRCLE_TEXTS: [
    '힘들다… 그냥 힘든걸까…\n지금 내가 겪고 있는\n이게 다 뭐지…?',
    '모든게 지치고, 공허하다\n지금의 나 괜찮은걸까…?',
    '내가 그렸던 삶은 이게 아닌데\n기댈 곳이 어디인지 모르겠어',
  ],
  DO_NOT_WORRY_SECTION_FOOTERS: [
    '이제 혼자 고민하지 말고',
    '닥터리슨에서 마음건강을 확인해 보세요',
  ],
  DO_NOT_WORRY_SECTION_FOOTERS_MOBILE: [
    '이제 혼자 고민하지 말고',
    '닥터리슨에서',
    '마음건강을 확인해 보세요',
  ],

  EASY_USE_SECTION_ACCENT: '언제 어디서나 쉽고 간편하게 이용하세요.',
  EASY_USE_SECTION_HEADS: ['모바일로 간편하게 검사하고,', '분석 결과까지 한번에!'],

  HOLISTIC_ASSESSMENT_SECTION_DIAGRAM_MENTAL: '정신적',
  HOLISTIC_ASSESSMENT_SECTION_DIAGRAM_PHYSICAL: '신체적',
  HOLISTIC_ASSESSMENT_SECTION_DIAGRAM_SOCIAL: '사회적',
  HOLISTIC_ASSESSMENT_SECTION_DIAGRAM_HOLISTIC: '전인적',

  HOLISTIC_ASSESSMENT_SECTION_ACCENT: '단순 우울증 선별 도구가 아니에요.',
  HOLISTIC_ASSESSMENT_SECTION_HEADS: ['다각도의 마음건강과', '생활습관까지 종합적으로'],
  HOLISTIC_ASSESSMENT_SECTION_BODY:
    '정신건강 관리 여정의 첫 번째, 가장 중요한 단계는\n나 자신의 상태를 명확하게 아는 것입니다.\n닥터리슨은 정신적+신체적+사회적 요소 모두 고려한\n전인적 자가평가로 현재 상태를 명확하게 알 수 있습니다.',

  COLLABORATIVE_DEVELOPMENT_SECTION_ACCENT: '마음건강 전문가와 함께 만들었어요.',
  COLLABORATIVE_DEVELOPMENT_SECTION_HEADS: ['서울대학교병원', '정신건강의학전문의\n공동 개발'],
  COLLABORATIVE_DEVELOPMENT_SECTION_CIRCLE_TEXT: '20년 이상\n임상 경험',

  ABOUT_SERVICE_SECTION_ACCENT: '마음건강 AI 자가평가 및 관리 서비스',
  ABOUT_SERVICE_SECTION_HEADS: ['현재 마음건강 상태를\n명확하게 알고', '적합한 솔루션을 찾으세요'],
  ABOUT_SERVICE_SECTION_BUTTON: '서비스 자세히 보기',

  REVIEW_SECTION_HEAD: '닥터리슨 이용후기를\n확인해보세요.',
  REVIEW_CARD_SCORES: [5, 4, 5, 4, 5, 5],
  REVIEW_CARD_TITLES: [
    '많은 사람들이\n꼭 써보길',
    '현대인에게\n꼭 필요한 앱 같아요',
    '상태변화를\n확인할 수 있어서 좋아요',
    '제가 우울증, 조울증인지\n몰랐는데!',
    '객관적이어서\n도움이 됐어요',
    '굿 굿\n쭉 쓸 것 같습니다',
  ],
  REVIEW_CARD_CONTENTS: [
    '내가 나의 상태를 모른다는 것이 얼마나\n위험한 것인지 모르는 사람이 많다.\n그냥 하루하루를 살아내는 우리나라에\n많은 사람들이 꼭 써보길 추천함.\n내 상태를 안다는 것만으로 많은 것들이\n바뀌는 시작일 수 있다.',
    '지인이 추천해줘서 써봤는데\n평소 나는 아무런 문제가 없다고 생각했는데..!\n생각하지 못했던 결과라 당황스럽기도 했지만 도움이 됐어요. 많은 사람들이 이런\n좋은 서비스를 이용했으면 좋겠습니다.',
    '요새 좀 우울했는데 힘들 때마다 체크하면서 상태변화를 확인하고 있어요.\n내년에는 제 상태가 아주 좋아지길 기대해요.\n앱이 업데이트가 자주돼서\n더 좋아지는 것 같아요.',
    '검사해보니까 우울증/조울증 주의네요...\n요즘 평소보다 짜증이 많이 나고\n일도 손에 잘 안 잡혔는데\n이유를 좀 알 것 같아요..\n앱 잠금도 있고 믿고 쓸 수 있을 것 같네요.',
    '스트레스 받을 일이 많아서 써봤는데\n객관적으로 볼 수 있어서 도움이 됐어요.\n상담 예약 잡는 것도 불편하고\n맨날 까먹었는데',
    '비대면이라서 자기자신에게\n더 솔직해질 수 있습니다.\n앞으로 정신건강과 자기멘탈이 중요해질 텐데\n어플로 관리하면 좋을 것 같습니다.',
  ],
  REVIEW_CARD_NICKNAMES: ['Sar******님', '알**님', '일***님', '김**님', 'YH***님', 'Ell*****님'],

  CORE_VISION_SECTION_ACCENT: '우리 모두의 삶의 질 향상을 추구합니다.',
  CORE_VISION_SECTION_HEADS: ['닥터리슨의', '핵심가치'],
  CORE_VISION_SECTION_BUTTON: '핵심가치 자세히 보기',

  FOOTER_STORE_BUTTON_SECTION_HEADS: [
    '복잡하고 알기 어려운 내 마음건강',
    '닥터리슨과 지금 바로 확인해보세요',
  ],

  INVITATION_BANNER_TITLE:
    '초대 링크를 통해 회원가입하면 <c>친구와 나에게 무료 이용권 쿠폰</c>을 드려요',
  INVITATION_BANNER_TITLE_MOBILE: '초대 링크를 통해 회원가입하면 <c>무료 이용권 쿠폰</c>을 드려요',
  INVITATION_BANNER_DO_NOT_SHOW_TODAY: '오늘 하루 보지 않기',
} as const;
