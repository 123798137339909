import {
  Outer,
  Container,
  TextContainer,
  ImageContainer,
  HeadText,
  CharacterImage,
} from './CheeringSection.styles';

import { cheering_section_character } from '@assets';

import { Strings } from '@strings';
import { getStyledText } from '@utils/styles';

const CheeringSection = () => {
  return (
    <Outer>
      <Container>
        <TextContainer>
          <HeadText enableMarginBottomInMobile>
            {getStyledText(Strings.VISION_CHEERING_SECTION_HEADS[0], {
              bold: true,
            })}
          </HeadText>
          <HeadText bold>{Strings.VISION_CHEERING_SECTION_HEADS[1]}</HeadText>
        </TextContainer>
        <ImageContainer>
          <CharacterImage src={cheering_section_character} alt='cheering_section_character' />
        </ImageContainer>
      </Container>
    </Outer>
  );
};

export default CheeringSection;
