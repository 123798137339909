import styled from 'styled-components';

import { Text } from '@components/Text/Text';

import { Colors, Media } from '@styles';
import { CONTENT_CONTAINER_WIDTH } from '@utils';

export const Outer = styled.div`
  width: 100%;

  background-color: ${Colors.blue50};
`;

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: ${CONTENT_CONTAINER_WIDTH}px;
  margin: 0 auto;
  padding: 50px 73px;

  ${Media.mobile} {
    flex-direction: column-reverse;
    align-items: flex-start;

    padding: 56px 30px 0px 30px;

    width: 100%;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
`;

export const ImageContainer = styled(InnerContainer)`
  ${Media.mobile} {
    align-self: center;

    margin-top: 41.92px;
    margin-bottom: 25px;
  }
`;

export const VennDiagramImage = styled.img`
  width: 350px;
  height: 322px;

  ${Media.mobile} {
    width: 315px;
    height: 289.8px;
  }
`;

const AbsoluteText = styled(Text.H4)`
  position: absolute;
  z-index: 1;

  color: ${Colors.black};
`;

export const MentalText = styled(AbsoluteText)`
  top: 24px;
  left: 50%;
  transform: translateX(-50%);

  ${Media.mobile} {
    top: 21.6px;
  }
`;

export const PhysicalText = styled(AbsoluteText)`
  top: 197px;
  left: 24px;

  ${Media.mobile} {
    top: 177.3px;
  }
`;

export const SocialText = styled(AbsoluteText)`
  top: 197px;
  right: 27px;

  ${Media.mobile} {
    top: 177.3px;
  }
`;

export const HolisticText = styled(AbsoluteText)`
  top: 166px;
  left: 50%;
  transform: translateX(-50%);

  color: ${Colors.white};

  ${Media.mobile} {
    top: 149.4px;
  }
`;

export const AccentText = styled(Text.H4)`
  margin-bottom: 20px;

  ${Media.mobile} {
    margin-bottom: 16px;
  }
`;

export const H2WithMargin = styled(Text.H2)`
  margin-bottom: 6px;
`;

export const BodyText = styled(Text.H4)`
  margin-top: 20px;

  color: ${Colors.gray500};

  ${Media.mobile} {
    margin-top: 16px;
  }
`;
