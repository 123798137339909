import styled from 'styled-components';

import { Text } from '@components/Text/Text';

import { CONTENT_CONTAINER_WIDTH } from '@utils';

import { Colors, Fonts, Media } from '@styles';

export const Outer = styled.div`
  width: 100%;

  background-color: ${Colors.blue50};
`;

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: ${CONTENT_CONTAINER_WIDTH}px;
  margin: 0 auto;
  padding: 50px 73px;

  ${Media.mobile} {
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    padding: 56px 30px 98px 30px;
    margin: unset;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
`;

export const ImageContainer = styled(InnerContainer)`
  ${Media.mobile} {
    align-items: flex-end;

    width: 100%;
  }
`;

export const AccentText = styled(Text.H4)`
  margin-bottom: 20px;
`;

export const H2WithMargin = styled(Text.H2)`
  margin-bottom: 6px;
`;

type TextCircleProps = {
  eng?: boolean;
};
export const TextCircle = styled.div<TextCircleProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  top: 50%;
  right: ${(props) => (props.eng ? 177 : 312)}px;
  transform: translateY(-50%);
  width: ${(props) => (props.eng ? 224 : 247)}px;
  height: ${(props) => (props.eng ? 224 : 247)}px;
  border-radius: ${247 / 2}px;

  background-color: ${Colors.blue400};

  ${Fonts.Medium};
  font-weight: ${(props) => (props.eng ? 400 : 500)};
  font-size: ${(props) => (props.eng ? 28 : 38)}px;
  line-height: ${(props) => (props.eng ? 33.41 : 45.35)}px;
  letter-spacing: -2.5px;

  white-space: pre-wrap;
  text-align: center;
  color: ${Colors.white};

  ${Media.mobile} {
    left: 0;
    width: 163px;
    height: 163px;
    border-radius: ${163 / 2}px;

    font-size: 25px;
    line-height: 29.93px;
    letter-spacing: -1.65px;
  }
`;

type DoctorImageProps = {
  eng?: boolean;
};
export const DoctorImage = styled.img<DoctorImageProps>`
  z-index: 1;

  width: ${(props) => (props.eng ? 229 : 357)}px;
  height: auto;

  margin-top: ${(props) => (props.eng ? 50 : 0)}px;
  margin-bottom: ${(props) => (props.eng ? 50 : 0)}px;

  ${Media.mobile} {
    width: auto;
    height: auto;
    max-width: 60%;
    max-height: 300px;
  }
`;
