// import { useSelector } from 'react-redux';
// import { RootState } from '@store';
// import { Container, Loader } from './Loading.styles';
import { Fragment } from 'react';
// import Lottie from 'lottie-web';
// import { lottie_loader } from '@assets';

const Loading = () => {
  return <Fragment />;

  // const { loading } = useSelector((state: RootState) => state.CommonReducer);

  // const containerRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (containerRef.current && loading) {
  //     Lottie.loadAnimation({
  //       container: containerRef.current,
  //       renderer: 'svg',
  //       name: 'Loader',
  //       loop: true,
  //       autoplay: true,
  //       animationData: lottie_loader,
  //     });
  //   }
  //   if (!loading) {
  //     Lottie.destroy('Loader');
  //   }
  //   return () => {
  //     Lottie.destroy('Loader');
  //   };
  // }, []);

  // if (!loading) return <Fragment />;
  // return (
  //   <Container ref={containerRef}>
  //     <Loader />
  //   </Container>
  // );
};

export default Loading;
