import { icon_app_store, icon_google_play } from '@assets';
import { Strings } from '@strings';
import { AppScheme, isMobile, StoreType, StoreUrl } from '@utils';
import { Fragment, HTMLAttributes } from 'react';
import { Button, StoreIcon } from './StoreButton.styles';

interface StoreButtonProps extends HTMLAttributes<HTMLButtonElement> {
  storeType: StoreType;
}

const StoreButton = (props: StoreButtonProps) => {
  const { storeType, ...rest } = props;

  const onClick = () => {
    if (!isMobile) {
      window.location.href = StoreUrl[storeType];
      return;
    }

    window.location.href = AppScheme[storeType === StoreType.APP_STORE ? 'IOS' : 'ANDROID'];

    setTimeout(() => {
      if (!(document.webkitHidden || document.hidden)) {
        window.location.href = StoreUrl[storeType];
      }
    }, 100);
  };

  return (
    <Button {...rest} onClick={onClick}>
      {storeType === StoreType.APP_STORE && (
        <Fragment>
          <StoreIcon src={icon_app_store} alt='appStoreIcon' />
          {Strings.Common.APP_STORE}
        </Fragment>
      )}
      {storeType === StoreType.GOOGLE_PLAY && (
        <Fragment>
          <StoreIcon src={icon_google_play} alt='googlePlayIcon' />
          {Strings.Common.GOOGLE_PLAY}
        </Fragment>
      )}
    </Button>
  );
};

export default StoreButton;
