import { Text } from '@components/Text/Text';

import { Colors, Media } from '@styles';
import { CONTENT_CONTAINER_WIDTH } from '@utils';
import styled from 'styled-components';

export const Outer = styled.div`
  width: 100%;

  background-color: ${Colors.blue50};
`;

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: ${CONTENT_CONTAINER_WIDTH}px;
  padding: 50px 73px;
  margin: 0 auto;

  ${Media.mobile} {
    flex-direction: column;
    align-items: flex-start;

    padding: 60px 30px 0px 30px;

    width: 100%;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const H4WithMargin = styled(Text.H4)`
  margin-bottom: 20px;
`;

export const H2WithMargin = styled(Text.H2)`
  margin-bottom: 6px;
`;

export const PhoneImage = styled.img`
  width: 432px;
  height: 400px;

  ${Media.mobile} {
    width: 368px;
    height: 340.74px;
    margin-top: 16px;
  }
`;
