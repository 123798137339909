import { Fragment, useContext } from 'react';

import { Container, HeadText, Outer } from './SolutionSection.styles';

import { LanguageContext } from '@context';

import { Language, Strings } from '@strings';

const SolutionSection = () => {
  const { language } = useContext(LanguageContext);
  return (
    <Outer>
      <Container>
        {language === Language.KO && (
          <Fragment>
            <HeadText enableMarginBottom>{Strings.VISION_SOLUTION_SECTION_HEADS[0]}</HeadText>
            <HeadText bold>{Strings.VISION_SOLUTION_SECTION_HEADS[1]}</HeadText>
          </Fragment>
        )}
        {language === Language.EN && (
          <Fragment>
            <HeadText enableMarginBottom bold>
              {Strings.VISION_SOLUTION_SECTION_HEADS[0]}
            </HeadText>
            <HeadText>{Strings.VISION_SOLUTION_SECTION_HEADS[1]}</HeadText>
          </Fragment>
        )}
      </Container>
    </Outer>
  );
};

export default SolutionSection;
