import { Colors } from '@styles';
import { MAX_WIDTH_MOBILE } from '@utils';
import styled from 'styled-components';

interface ContainerProps {
  isOpened: boolean;
  isEventBannerShown: boolean;
}
export const Container = styled.div<ContainerProps>`
  z-index: 2;
  display: none;
  flex-direction: column;
  position: fixed;

  width: 100vw;

  background-color: ${Colors.white};

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    top: ${({ isOpened, isEventBannerShown }) => {
      if (!isOpened) return '-100%';

      const bannerTopMargin = isEventBannerShown ? 60 : 0;
      return `${55 + bannerTopMargin}px`;
    }};

    display: flex;
    transition: top 0.2s ease-out;
  }
`;

export const MenuButton = styled.button`
  border: none;

  background-color: ${Colors.white};

  padding: 15px;

  text-align: left;
  cursor: pointer;
`;
