import styled from 'styled-components';
import { Colors, Fonts } from '@styles';
import { CONTENT_CONTAINER_WIDTH, MAX_WIDTH_MOBILE } from '@utils';

export const HEADER_HEIGHT_DESKTOP = 85;
export const HEADER_HEIGHT_MOBILE = 60;

export const Container = styled.section`
  height: ${HEADER_HEIGHT_DESKTOP}px;
  top: 0;
  left: 0;
  width: 100vw;

  position: sticky;
  display: flex;
  background-color: ${Colors.blue400};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 100;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    height: ${HEADER_HEIGHT_MOBILE}px;
  }
`;

export const Banner = styled.section`
  width: ${CONTENT_CONTAINER_WIDTH}px;
  height: 100%;
  display: flex;

  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BannerText = styled.span`
  ${Fonts.Medium};
  color: ${Colors.white};
  font-size: 22px;
  line-height: 18px;
  letter-spacing: -1px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const Bold = styled.span`
  ${Fonts.Bold};
`;

export const CouponImage = styled.img`
  width: 107px;
  height: 66px;

  margin-left: 28px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: none;
  }
`;

export const DontShowButton = styled.button`
  ${Fonts.Medium};
  color: ${Colors.blue80};
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.5px;

  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  top: 10px;
  border: none;
  cursor: pointer;
  background-color: ${Colors.transparent};

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    font-size: 0px;
    top: 19px;
  }
`;

export const CloseIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;
