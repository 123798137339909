import { coupon_usage_1, coupon_usage_2, coupon_usage_3, icon_arrow_right } from '@assets';
import { TitleWithUnderline } from '@pages/home/components';
import { Strings } from '@strings';
import { Fragment } from 'react';
import {
  Container,
  GuideDescription,
  GuideImage,
  GuideImageContainer,
  NumberLabel,
  RightArrow,
  TitleContainer,
  UsageGuideImages,
} from './UsageGuideSection.styles';

const UsageGuideSection = () => {
  const images = [coupon_usage_1, coupon_usage_2, coupon_usage_3];

  return (
    <Container>
      <TitleContainer>
        <TitleWithUnderline>{Strings.Coupon.USAGE_GUIDE.TITLE}</TitleWithUnderline>
      </TitleContainer>
      <UsageGuideImages>
        {images.map((each, idx) => (
          <Fragment key={idx}>
            <GuideImageContainer>
              <GuideImage src={each} alt={`couponUsageGuideImage-${idx + 1}`} />
              <GuideDescription>
                <NumberLabel>{idx + 1}</NumberLabel>
                {Strings.Coupon.USAGE_GUIDE.DESCRIPTION[idx]}
              </GuideDescription>
            </GuideImageContainer>
            {idx < images.length - 1 && <RightArrow src={icon_arrow_right} alt='rightArrow' />}
          </Fragment>
        ))}
      </UsageGuideImages>
    </Container>
  );
};

export default UsageGuideSection;
