import { CSSProperties, InputHTMLAttributes } from 'react';
import { Container, ErrorMessage, Label, StyledInput } from './Input.styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChangeValue: (value: string) => void;
  label?: string;
  placeholder?: string;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  errorMessage?: string;
}

const Input = (props: InputProps) => {
  const { value, label, onChangeValue, placeholder, errorMessage, style, inputStyle, ...rest } =
    props;

  return (
    <Container style={style}>
      {label && <Label>{label}</Label>}
      <StyledInput
        {...rest}
        value={value}
        onChange={(event) => onChangeValue(event.target.value)}
        placeholder={placeholder}
        style={inputStyle}
      />
      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};

export default Input;
