export const Service = {
  SERVICE_MANAGEMENT_SECTION_SUB_TITLE: '마음건강도 신체건강처럼\n주기적 확인과 관리가 중요합니다',
  SERVICE_MANAGEMENT_SECTION_TITLE: '닥터리슨으로 확인하고\n관리해보세요',
  SERVICE_MANAGEMENT_SECTION_STEP_TITLE: [
    '시작하기',
    '자가평가 하기',
    '결과 확인하기',
    '내 마음 기록하기',
    '주기적으로\n검사하며 관리하기',
  ],
  SERVICE_MANAGEMENT_SECTION_STEP_DESCRIPTION: [
    '간편하게 회원가입하고\n비밀번호를 설정해요',
    '맞춤형 질문에 따라\n마음건강을 확인해요',
    '마음건강 현재 상태와\n관리 팁 및 솔루션을 확인해요',
    '매일매일 나의 감정과\n상태 및 감사일기를 기록해요',
    '일회성이 아닌 주기적 검사로\n내 마음건강 변화추이를 확인해요',
  ],

  SERVICE_SECURITY_SECTION_ACCENT: '언제 어디서나 간편하고 안전하게',
  SERVICE_SECURITY_SECTION_HEADS: ['이용자 보안이 최우선', '이용 정보는 안전하게'],
  SERVICE_SECURITY_SECTION_DESCRIPTION:
    '닥터리슨은 익명성을 보장하며\n앱 잠금번호 설정으로 안전하게 사용하실 수 있습니다.',

  SERVICE_SELF_ASSESSMENT_SECTION_ACCENT: '체계적이고 섬세하게 구성된',
  SERVICE_SELF_ASSESSMENT_SECTION_HEADS: ['나의 상태를 기반으로 하는', '맞춤형 AI 자가평가'],
  SERVICE_SELF_ASSESSMENT_SECTION_DESCRIPTION:
    '마음건강부터 생활습관 파악까지\n답변 내용에 따라 확인할 수 있는 평가 질문으로 구성됩니다.',
  SERVICE_SELF_ASSESSMENT_SECTION_LABELS: [
    '#우울증',
    '#조울증',
    '#월경전불쾌장애',
    '#자해행위 및 자살',
    '#생활습관',
  ],

  SERVICE_REPORT_SECTION_ACCENT: 'AI 분석으로 빠르게 확인하는',
  SERVICE_REPORT_SECTION_HEADS: ['나의 마음건강에 대한', '자세한 분석 리포트'],
  SERVICE_REPORT_SECTION_DESCRIPTION:
    '현재의 상태와 증상을 빠르게 확인하여\n쉽고 상세하게 설명해 드립니다.',

  SERVICE_NOTE_SECTION_ACCENT: '정신건강 개선 연구 결과 기반',
  SERVICE_NOTE_SECTION_HEADS: ['피드형 구성으로', '간편하고 빠른 기록 기능'],
  SERVICE_NOTE_SECTION_DESCRIPTION:
    '정신건강 개선 연구 결과를 기반으로 한\n기록 가이드를 제공하여\n나의 감정과 상태를 기록하고 관리할 수 있습니다.',

  SERVICE_TREND_SECTION_ACCENT: '주기적인 자가평가로',
  SERVICE_TREND_SECTION_HEADS: ['마음건강 변화추이', '확인 및 지속적 관리'],
  SERVICE_TREND_SECTION_DESCRIPTION:
    '일회성 검사로 파편화된 데이터가 아닌\n누적 데이터 기반으로 변화 확인이 쉽고\n지속적인 관리를 할 수 있습니다.',

  SERVICE_ENCRYPTION_SECTION_ACCENT: '안전하게 보호할게요',
  SERVICE_ENCRYPTION_SECTION_HEADS: ['주요 이용 데이터는', '암호화 및 비식별화 처리'],
  SERVICE_ENCRYPTION_SECTION_DESCRIPTION:
    '닥터리슨을 통해 나온\n모든 결과 및 기록은 의료기록이 남지 않고,\n익명으로 진행되어 비밀이 보장됩니다.',
} as const;
