import { Colors, Fonts } from '@styles';
import { MAX_WIDTH_MOBILE } from '@utils';
import styled from 'styled-components';

export const Container = styled.section`
  background-color: ${Colors.blue400};
  height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    height: 373px;
  }
`;

export const Text = styled.h2`
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -1px;
  color: ${Colors.white};
  ${Fonts.Regular};
  margin: 0;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    font-size: 26px;
    line-height: 32.55px;
  }
`;

export const BoldText = styled.h2`
  font-size: 44px;
  line-height: 55px;
  letter-spacing: -1px;
  color: ${Colors.white};
  ${Fonts.Bold};
  margin: 0;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    font-size: 26px;
    line-height: 32.55px;
  }
`;

export const CouponImage = styled.img`
  margin-top: 30px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    margin-top: 20px;
    width: 267px;
  }
`;
