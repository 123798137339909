export const formatCouponId = (id: string) => {
  const temp = id.padEnd(16, '_');
  return temp.replace(/([\w|_]{4})([\w|_]{4})([\w|_]{4})([\w|_]{4})/, '$1-$2-$3-$4');
};

export const isValidCouponId = (id: string) => {
  return /^([A-Z0-9]{16})$/.test(id);
};

export const isValidEmail = (value: string) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w\w+)+$/.test(value);
};
