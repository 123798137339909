import styled from 'styled-components';

import { MAX_WIDTH_MOBILE } from '@utils';

import { Colors } from '@styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 478px;

  background-color: ${Colors.gray80};

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: block;

    height: auto;
    padding: 70px 30px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 1170px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: auto;
    row-gap: 20px;
  }
`;

export const NoticeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Head = styled.div`
  margin-bottom: 20px;
`;

export const KeyValue = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  column-gap: 30px;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  margin-top: 20px;
`;
