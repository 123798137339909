import styled from 'styled-components';

import { Text } from '@components/Text/Text';
import { CommonContainer, CommonOuter } from '../common';

import { Colors, Flex, Media } from '@styles';

export const Outer = styled(CommonOuter)`
  background-color: ${Colors.white};
`;

export const Container = styled(CommonContainer)`
  padding: 129.5px 73px;

  ${Media.mobile} {
    align-items: flex-start;
    flex-direction: column-reverse;

    padding: 60px 30px;
  }
`;

export const TextContainer = styled.div`
  ${Flex.Column};
`;

export const PieChartContainer = styled.div`
  ${Flex.Column};
  align-items: center;

  padding-left: 132px;

  ${Media.mobile} {
    align-self: flex-end;

    padding: unset;
    margin-top: 69px;
  }
`;

export const ChartImage = styled.img`
  width: 378px;

  ${Media.mobile} {
    max-width: 354px;
    width: 100%;
  }
`;

export const ChartDescriptionTexts = styled(Text.H4)`
  margin-top: 12px;

  text-align: center;

  color: ${Colors.gray500};
`;

export const AccentText = styled(Text.H4)`
  margin-bottom: 20px;

  color: ${Colors.blue400};

  ${Media.mobile} {
    margin-bottom: 16px;
  }
`;

type HeadTextProps = {
  marginBottom?: number;
};
export const HeadText = styled(Text.H2)<HeadTextProps>`
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;

export const DescriptionText = styled(Text.H4)`
  margin-top: 20px;

  color: ${Colors.gray500};

  ${Media.mobile} {
    margin-top: 16px;
  }
`;
