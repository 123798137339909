import styled from 'styled-components';

import { Text } from '@components/Text/Text';
import { CommonContainer, CommonOuter } from '../common';

import { Colors, FlexColumn, Media } from '@styles';

export const Outer = styled(CommonOuter)``;

export const Container = styled(CommonContainer)`
  padding: 135px 73px;

  background-color: ${Colors.white};

  ${Media.mobile} {
    flex-direction: column-reverse;
    align-items: flex-start;

    padding: 60px 30px;
  }
`;

type InnerContainerProps = {
  alignRightInMobile?: boolean;
};
export const InnerContainer = styled.div<InnerContainerProps>`
  ${FlexColumn};

  ${Media.mobile} {
    align-items: ${(props) => (props.alignRightInMobile ? 'flex-end' : 'inherit')};
    width: 100%;
  }
`;

export const PhoneImage = styled.img`
  height: 541px;

  ${Media.mobile} {
    width: 100%;
    max-width: 320px;
    height: auto;
    margin-top: 56px;
  }
`;

export const AccentText = styled(Text.H4)`
  margin-bottom: 20px;

  ${Media.mobile} {
    margin-bottom: 16px;
  }
`;

type HeadTextProps = {
  marginBottom?: number;
};
export const HeadText = styled(Text.H2)<HeadTextProps>`
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;

export const DescriptionText = styled(Text.H4)`
  margin-top: 20px;

  ${Media.mobile} {
    margin-top: 16px;
  }
`;
