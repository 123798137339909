import { Fragment } from 'react';
import {
  AccuracySection,
  CheeringSection,
  FirstStepSection,
  PieChartSection,
  SolutionSection,
  VisionKeywordSection,
} from './components';

const Vision = () => {
  return (
    <Fragment>
      <FirstStepSection />
      <PieChartSection />
      <SolutionSection />
      <AccuracySection />
      <VisionKeywordSection />
      <CheeringSection />
    </Fragment>
  );
};

export default Vision;
