import styled from 'styled-components';

import { Text } from '@components/Text/Text';

import { Colors, Media } from '@styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 20px;
  border-radius: 50px;

  background-color: ${Colors.blue80};

  ${Media.mobile} {
    padding: 7px 14px;
    border-radius: 35px;
  }
`;

export const LabelText = styled(Text.Body1)`
  color: ${Colors.blue400};

  ${Media.mobile} {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.7px;
  }
`;
