import { HTMLAttributes } from 'react';

import { Card, Content, NickName, Star, Stars, Title } from './ReviewCard.styles';

import { icon_star_fill_yellow400, icon_star_outline_yellow400 } from '@assets';

const REVIEW_MAX_SCORE = 5;

type ReviewCardProps = HTMLAttributes<HTMLDivElement> & {
  score: number;
  title: string;
  content: string;
  nickname: string;
};
const ReviewCard = (props: ReviewCardProps) => {
  const { content, score, title, nickname } = props;

  return (
    <Card>
      <Stars>
        {Array.from({ length: REVIEW_MAX_SCORE }).map((_, index) => (
          <Star
            key={index}
            src={score >= index + 1 ? icon_star_fill_yellow400 : icon_star_outline_yellow400}
          />
        ))}
      </Stars>
      <Title bold>{title}</Title>
      <Content>{content}</Content>
      <NickName>{nickname}</NickName>
    </Card>
  );
};

export default ReviewCard;
