import { Text } from '@components';
import {
  Outer,
  Container,
  InnerContainer,
  VennDiagramImage,
  MentalText,
  PhysicalText,
  SocialText,
  HolisticText,
  AccentText,
  BodyText,
  H2WithMargin,
  ImageContainer,
} from './HolisticAssessmentSection.styles';

import { holistic_assessment_venn_diagram } from '@assets';

import { Colors } from '@styles';

import { Strings } from '@strings';

const HolisticAssessmentSection = () => {
  return (
    <Outer>
      <Container>
        <ImageContainer>
          <VennDiagramImage
            src={holistic_assessment_venn_diagram}
            alt='holistic_assessment_venn_diagram'
          />
          <MentalText strong>{Strings.HOLISTIC_ASSESSMENT_SECTION_DIAGRAM_MENTAL}</MentalText>
          <PhysicalText strong>{Strings.HOLISTIC_ASSESSMENT_SECTION_DIAGRAM_PHYSICAL}</PhysicalText>
          <SocialText strong>{Strings.HOLISTIC_ASSESSMENT_SECTION_DIAGRAM_SOCIAL}</SocialText>
          <HolisticText strong>{Strings.HOLISTIC_ASSESSMENT_SECTION_DIAGRAM_HOLISTIC}</HolisticText>
        </ImageContainer>
        <InnerContainer>
          <AccentText strong color={Colors.blue400}>
            {Strings.HOLISTIC_ASSESSMENT_SECTION_ACCENT}
          </AccentText>
          <H2WithMargin color={Colors.black}>
            {Strings.HOLISTIC_ASSESSMENT_SECTION_HEADS[0]}
          </H2WithMargin>
          <Text.H2 strong color={Colors.black}>
            {Strings.HOLISTIC_ASSESSMENT_SECTION_HEADS[1]}
          </Text.H2>
          <BodyText>{Strings.HOLISTIC_ASSESSMENT_SECTION_BODY}</BodyText>
        </InnerContainer>
      </Container>
    </Outer>
  );
};

export default HolisticAssessmentSection;
