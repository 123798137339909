import styled from 'styled-components';

import { Text } from '@components/Text/Text';
import { CommonContainer, CommonOuter } from '../common';

import { Colors, Flex, Media } from '@styles';

export const Outer = styled(CommonOuter)`
  background: linear-gradient(92.93deg, #62a7fb 35.45%, #3c7ae5 66.95%);
`;

export const Container = styled(CommonContainer)`
  ${Flex.Column};

  padding: 60px 0px;
`;

type HeadTextProps = {
  enableMarginBottom?: boolean;
};
export const HeadText = styled(Text.H2)<HeadTextProps>`
  color: ${Colors.white};

  text-align: center;

  ${Media.mobile} {
    margin-bottom: ${(props) => (props.enableMarginBottom ? 6 : 0)}px;

    font-size: 24px;
    line-height: 29px;
    letter-spacing: -2px;
  }
`;
