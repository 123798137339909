import { LanguageContext } from '@context';
import { HTMLAttributes, useContext } from 'react';
import {
  CommonProps,
  H2StyledProps,
  H4StyledProps,
  StyledBody1,
  StyledBody2,
  StyledFooter,
  StyledH2,
  StyledH3,
  StyledH4,
} from './Text.styles';

interface H2Props extends HTMLAttributes<HTMLHeadingElement>, Omit<H2StyledProps, 'language'> {}
const H2 = (props: H2Props) => {
  const { language } = useContext(LanguageContext);
  return <StyledH2 {...props} language={language} />;
};

interface H4Props extends HTMLAttributes<HTMLHeadingElement>, Omit<H4StyledProps, 'language'> {}
const H4 = (props: H4Props) => {
  const { language } = useContext(LanguageContext);
  return <StyledH4 {...props} language={language} />;
};

type H3Props = HTMLAttributes<HTMLHeadingElement> & Omit<H4StyledProps, 'language'>;
const H3 = (props: H3Props) => {
  const { language } = useContext(LanguageContext);
  return <StyledH3 {...props} language={language} />;
};

interface Body1Props extends HTMLAttributes<HTMLSpanElement>, Omit<CommonProps, 'language'> {}
const Body1 = (props: Body1Props) => {
  const { color, ...rest } = props;
  const { language } = useContext(LanguageContext);
  return <StyledBody1 {...rest} color={color} language={language} />;
};

interface Body2Props extends HTMLAttributes<HTMLSpanElement>, Omit<H4StyledProps, 'language'> {}
const Body2 = (props: Body2Props) => {
  const { color, ...rest } = props;
  const { language } = useContext(LanguageContext);
  return <StyledBody2 {...rest} color={color} language={language} />;
};

interface FooterProps extends HTMLAttributes<HTMLSpanElement>, Omit<H4StyledProps, 'language'> {}
const Footer = (props: FooterProps) => {
  const { language } = useContext(LanguageContext);
  return <StyledFooter {...props} language={language} />;
};

export const Text = {
  H2,
  H4,
  H3,
  Body1,
  Body2,
  Footer,
};
