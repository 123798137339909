import styled, { css } from 'styled-components';

import { Text } from '@components/Text/Text';

import { Colors, FlexColumn, FlexRow, Fonts, Media } from '@styles';

export const Container = styled.div`
  ${FlexColumn};
  align-items: center;

  row-gap: 20px;

  ${Media.mobile} {
    row-gap: 11px;
  }
`;

export const CircleContainer = styled.div`
  ${FlexRow};
  align-items: center;

  ${Media.mobile} {
    width: 100%;
    height: 100%;
  }
`;

type CircleProps = {
  fill?: boolean;
};
export const Circle = styled.div<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 206px;
  height: 206px;
  border: 2px solid ${Colors.white};
  border-radius: 206px;

  background-color: ${(props) => (props.fill ? Colors.white : Colors.transparent)};

  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));

  ${Media.mobile} {
    max-width: 117px;
    max-height: 117px;
    border-radius: 100%;
    border: 1px solid ${Colors.white};
  }
`;

type CircleInnerTextProps = {
  fill?: boolean;
};
export const CircleInnerText = styled(Text.H4)<CircleInnerTextProps>`
  text-align: center;

  color: ${(props) => (props.fill ? Colors.blue400 : Colors.white)};

  ${Media.mobile} {
    font-size: 11px;
    line-height: 17px;
    letter-spacing: -0.57px;
  }
`;

export const CircleBridge = styled.div`
  right: 0px;
  width: 16px;
  height: 2px;

  background-color: ${Colors.white};

  ${Media.mobile} {
    width: 9px;
    height: 1.14px;
  }
`;

type DescriptionTextProps = {
  bridge?: boolean;
  small?: boolean;
};
export const DescriptionText = styled(Text.Body1)<DescriptionTextProps>`
  margin-right: ${(props) => (props.bridge ? 16 : 0)}px;

  ${Fonts.Medium};
  font-size: 16.5px;
  text-align: center;

  color: ${Colors.white};

  ${(props) =>
    props.small &&
    css`
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -1.1px;
    `}

  ${Media.mobile} {
    margin-right: ${(props) => (props.bridge ? 9 : 0)}px;

    font-size: 10px;
    line-height: 13px;
    letter-spacing: -0.57px;

    ${(props) =>
      props.small &&
      css`
        font-size: 9.6px;
        line-height: 13px;
        letter-spacing: -0.9px;
      `}
  }
`;
