export const Urls = {
  HOME: '/',
  COUPON: '/coupon',
  INVITATION: '/invitation',
  SHARE: '/share',

  ABOUT_SERVICE: '/service',
  CORE_VALUES: '/core_values',

  // * unused
  SUPPORT1029: '/support1029',
};
