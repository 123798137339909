import styled from 'styled-components';

import { MAX_WIDTH_MOBILE } from '@utils';

import { Colors } from '@styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 800px;

  background-color: ${Colors.white};

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: block;

    height: auto;

    padding: 70px 30px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 1170px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    position: relative;
    flex-wrap: wrap-reverse;
    justify-content: initial;

    width: auto;
    row-gap: 47px;
  }
`;

export const MockPhoneImg = styled.img`
  height: 541px;
  margin-right: 176px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    width: 100%;
    max-width: 340px;
    height: initial;
    margin: 0 auto;
  }
`;

export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 20px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    row-gap: 16px;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  column-gap: 4px;
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 6px;
  border-radius: 6px;

  background-color: ${Colors.gray100};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  column-gap: 20px;
  margin-top: 20px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    margin-top: 0px;
  }
`;
