import { useContext } from 'react';

import {
  Outer,
  Container,
  InnerContainer,
  PhoneImage,
  AccentText,
  HeadText,
  DescriptionText,
} from './SecuritySection.styles';

import { LanguageContext } from '@context';

import { security_section_phone, security_section_phone_eng } from '@assets';
import { Colors } from '@styles';

import { Language, Strings } from '@strings';

const SecuritySection = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Outer>
      <Container>
        <InnerContainer alignRightInMobile>
          {language === Language.KO && (
            <PhoneImage src={security_section_phone} alt='security_section_phone' />
          )}
          {language === Language.EN && (
            <PhoneImage src={security_section_phone_eng} alt='security_section_phone_eng' />
          )}
        </InnerContainer>
        <InnerContainer>
          <AccentText bold color={Colors.blue400}>
            {Strings.SERVICE_SECURITY_SECTION_ACCENT}
          </AccentText>
          <HeadText>{Strings.SERVICE_SECURITY_SECTION_HEADS[0]}</HeadText>
          <HeadText bold marginBottom={6}>
            {Strings.SERVICE_SECURITY_SECTION_HEADS[1]}
          </HeadText>
          <DescriptionText color={Colors.gray500}>
            {Strings.SERVICE_SECURITY_SECTION_DESCRIPTION}
          </DescriptionText>
        </InnerContainer>
      </Container>
    </Outer>
  );
};

export default SecuritySection;
