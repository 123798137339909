import styled from 'styled-components';

import { CONTENT_CONTAINER_WIDTH } from '@utils';

import { Colors, Fonts, Media } from '@styles';
import { Link } from 'react-router-dom';

export const CommonOuter = styled.div`
  width: 100%;
`;

export const CommonContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: ${CONTENT_CONTAINER_WIDTH}px;
  margin: 0 auto;

  ${Media.mobile} {
    width: 100%;
  }
`;

export const CommonButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 204px;
  height: 56px;
  border: none;
  border-radius: 12px;

  background-color: ${Colors.blue400};

  ${Fonts.Medium};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.7px;
  color: ${Colors.white};

  cursor: pointer;
`;

export const CommonLinkButton = styled(Link)`
  all: unset;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 204px;
  height: 56px;
  border: none;
  border-radius: 12px;

  background-color: ${Colors.blue400};

  ${Fonts.Medium};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.7px;
  color: ${Colors.white};

  cursor: pointer;
`;
