// components
import { Container, Li, NoticeContainer, NoticeIcon, Title, Ul } from './NoticeSection.styles';
// strings
import { Strings } from '@strings';
// assets
import { icon_notice } from '@assets';

const NoticeSection = () => {
  return (
    <Container>
      <NoticeContainer>
        <Title>
          <NoticeIcon src={icon_notice} alt='noticeIcon' />
          {Strings.Invitation.Notice.TITLE}
        </Title>
        <Ul>
          {Strings.Invitation.Notice.BODY.map((each, idx) => (
            <Li key={idx}>{each}</Li>
          ))}
        </Ul>
      </NoticeContainer>
    </Container>
  );
};

export default NoticeSection;
