import styled from 'styled-components';

import { CommonOuter } from '../common';

import { FlexColumn, FlexRow, Fonts, Media } from '@styles';
import { Text } from '@components';

export const Outer = styled(CommonOuter)`
  background: linear-gradient(92.93deg, #62a7fb 35.45%, #3c7ae5 66.95%);
`;

export const Container = styled.section`
  ${FlexColumn};
  align-items: center;

  padding-top: 110px;
  padding-bottom: 110px;

  ${Media.mobile} {
    padding: 60px 0px;
  }
`;

export const Head = styled(Text.H2)`
  white-space: nowrap;

  text-align: center;

  ${Media.mobile} {
    ${Fonts.h3}

    white-space: pre-wrap;
  }
`;

export const Buttons = styled.div`
  ${FlexRow}

  column-gap: 20px;
  margin-top: 40px;

  ${Media.mobile} {
    margin-top: 16px;
  }
`;
