import { encryption_section_shield } from '@assets';
import { Strings } from '@strings';
import {
  Outer,
  AccentText,
  Container,
  DescriptionText,
  HeadText,
  ImageContainer,
  PhoneImage,
  TextContainer,
} from './EncryptionSection.styles';

const EncryptionSection = () => {
  return (
    <Outer>
      <Container>
        <TextContainer>
          <AccentText bold>{Strings.SERVICE_ENCRYPTION_SECTION_ACCENT}</AccentText>
          <HeadText marginBottom={6} enableLineBreakOnlyMobile>
            {Strings.SERVICE_ENCRYPTION_SECTION_HEADS[0]}
          </HeadText>
          <HeadText bold>{Strings.SERVICE_ENCRYPTION_SECTION_HEADS[1]}</HeadText>
          <DescriptionText>{Strings.SERVICE_ENCRYPTION_SECTION_DESCRIPTION}</DescriptionText>
        </TextContainer>
        <ImageContainer>
          <PhoneImage src={encryption_section_shield} alt='encryption_section_shield' />
        </ImageContainer>
      </Container>
    </Outer>
  );
};

export default EncryptionSection;
