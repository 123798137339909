import styled from 'styled-components';

import { MAX_WIDTH_MOBILE } from '@utils';

import { Colors } from '@styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 800px;

  background-color: ${Colors.gray80};

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: block;

    height: 667px;

    padding: 70px 30px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 1170px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    position: relative;

    width: auto;
    row-gap: 150px;
  }
`;

export const ProductImg = styled.img`
  width: 459.25px;
  height: 283px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    position: absolute;
    right: 0px;
    top: 361px;

    width: 298px;
    height: auto;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-right: 43px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    margin-right: 0px;
  }
`;

export const TextMarginTop = styled.div`
  margin-top: 20px;
`;
