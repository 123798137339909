import { LanguageContext } from '@context';
import { Language } from '@strings';
import { useContext } from 'react';
import {
  Container,
  Circle,
  CircleInnerText,
  DescriptionText,
  CircleBridge,
  CircleContainer,
} from './StepCircle.styles';

type StepCircleProps = {
  title: string;
  description: string;
  fill?: boolean;
  bridge?: boolean;
};
const StepCircle = (props: StepCircleProps) => {
  const { title, description, fill, bridge } = props;
  const { language } = useContext(LanguageContext);

  return (
    <Container>
      <CircleContainer>
        <Circle fill={fill}>
          <CircleInnerText fill={fill} bold>
            {title}
          </CircleInnerText>
        </Circle>
        {bridge && <CircleBridge />}
      </CircleContainer>
      <DescriptionText bridge={bridge} small={language === Language.EN}>
        {description}
      </DescriptionText>
    </Container>
  );
};

export default StepCircle;
