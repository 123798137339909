import { useContext } from 'react';

import {
  Container,
  HeadText,
  TextContainer,
  DescriptionText,
  Outer,
  ImageContainer,
  KeywordBackgroundImage,
  KeywordTextContainer,
  KeywordText,
  KeywordLabel,
} from './VisionKeywordSection.styles';

import { LanguageContext } from '@context';

import { Language, Strings } from '@strings';

const NUMBER_OF_KEYWORDS = Strings.VISION_KEYWORD_SECTION_KEYWORDS.length;

const VisionKeywordSection = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Outer>
      <Container>
        <ImageContainer>
          <KeywordBackgroundImage>
            {Array.from({ length: NUMBER_OF_KEYWORDS }).map((_, index) => (
              <KeywordTextContainer key={index}>
                <KeywordText small={language === Language.EN}>
                  {Strings.VISION_KEYWORD_SECTION_KEYWORDS[index]}
                </KeywordText>
                {language === Language.KO && (
                  <KeywordLabel>{Strings.VISION_KEYWORD_SECTION_KEYWORDS_ENG[index]}</KeywordLabel>
                )}
              </KeywordTextContainer>
            ))}
          </KeywordBackgroundImage>
        </ImageContainer>
        <TextContainer>
          <HeadText enableMarginBottom>{Strings.VISION_KEYWORD_SECTION_HEADS[0]}</HeadText>
          <HeadText bold>{Strings.VISION_KEYWORD_SECTION_HEADS[1]}</HeadText>
          <DescriptionText>{Strings.VISION_KEYWORD_SECTION_DESCRIPTION}</DescriptionText>
        </TextContainer>
      </Container>
    </Outer>
  );
};

export default VisionKeywordSection;
