import { Colors, Fonts } from '@styles';
import styled from 'styled-components';

export const DimmedBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${Colors.dimmedBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const Container = styled.div`
  z-index: 1;
  background-color: ${Colors.white};
  min-width: 335px;
  min-height: 213px;
  border-radius: 16px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h4`
  ${Fonts.body1};
  ${Fonts.Bold};
  margin: 0;

  color: ${Colors.black};
  text-align: center;
  white-space: pre-line;
`;

export const Body = styled.span`
  ${Fonts.body2};
  ${Fonts.Regular};

  color: ${Colors.gray600};
  text-align: center;
  white-space: pre-line;
  margin-top: 15px;
`;

export const Button = styled.button`
  ${Fonts.body2};
  ${Fonts.Bold};

  color: ${Colors.white};
  min-width: 110px;
  height: 46px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 12px;
  background-color: ${Colors.blue400};
  margin-top: 25px;

  cursor: pointer;
`;
