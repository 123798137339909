import styled from 'styled-components';

import { CircleCSS, Colors, FlexRow } from '@styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  column-gap: 48px;
`;

type ArrowButtonProps = {
  direction: 'left' | 'right';
};
export const ArrowButton = styled.button<ArrowButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  rotate: ${(props) => (props.direction === 'left' ? '180deg' : undefined)};

  ${CircleCSS(48 / 2)};
  border: none;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

  background-color: ${Colors.white};

  cursor: pointer;
`;

export const ArrowImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const Dots = styled.div`
  ${FlexRow};
  align-items: center;

  column-gap: 8px;
`;

type DotProps = {
  active?: boolean;
};
export const Dot = styled.div<DotProps>`
  ${CircleCSS(4)};

  background-color: ${(props) => (props.active ? Colors.blue400 : Colors.blue80)};
`;
