import { coupon } from '@assets';
import { Strings } from '@strings';
import { BoldText, Container, CouponImage, Text } from './MainImageSection.styles';

const MainImageSection = () => {
  return (
    <Container>
      <Text>{Strings.Coupon.MAIN_IMAGE.TEXT[0]}</Text>
      <BoldText>{Strings.Coupon.MAIN_IMAGE.TEXT[1]}</BoldText>
      <CouponImage src={coupon} />
    </Container>
  );
};

export default MainImageSection;
