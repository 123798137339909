import { ReactNode } from 'react';

import { Container, LabelText } from './Chip.styles';

type ChipProps = {
  children?: ReactNode;
};
const Chip = (props: ChipProps) => {
  const { children } = props;

  return (
    <Container>
      <LabelText medium>{children}</LabelText>
    </Container>
  );
};

export default Chip;
