import { css } from 'styled-components';
import { FontNames } from '@utils';

export const Fonts = {
  Bold: css`
    font-family: ${FontNames.SpoqaHanSansNeo.Bold};
    font-weight: 400;
  `,
  Medium: css`
    font-family: ${FontNames.SpoqaHanSansNeo.Medium};
    font-weight: 400;
  `,
  Regular: css`
    font-family: ${FontNames.SpoqaHanSansNeo.Regular};
    font-weight: 400;
  `,
  head1: css`
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -1px;
  `,
  head3: css`
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -1px;
  `,
  head4: css`
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -1px;
  `,
  body1: css`
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.7px;
  `,
  body2: css`
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.5px;
  `,
  h3: css`
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -2px;
  `,
};
