// strings
import { Strings } from '@strings';
// utils
import { INVITATION_CODE_LENGTH, mapWithCount } from '@utils';
// styles
import {
  CouponCardText,
  Container,
  CouponCard,
  Head,
  HeadContainer,
  CouponCodeBoxRow,
  CouponCodeBox,
  BoldHead,
} from './BannerSection.styles';

const BannerSection = () => {
  return (
    <Container>
      <HeadContainer>
        <Head>{Strings.Invitation.Banner.HEAD[0]}</Head>
        <BoldHead>{Strings.Invitation.Banner.HEAD[1]}</BoldHead>
      </HeadContainer>
      <CouponCard>
        <CouponCardText>{Strings.Invitation.Banner.INVITATION_CODE}</CouponCardText>
        <CouponCodeBoxRow>
          {mapWithCount(INVITATION_CODE_LENGTH, (idx) => (
            <CouponCodeBox key={idx} />
          ))}
        </CouponCodeBoxRow>
      </CouponCard>
    </Container>
  );
};

export default BannerSection;
