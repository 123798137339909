import {
  Container,
  H2WithMargin,
  H4WithMargin,
  InnerContainer,
  Outer,
  PeopleImage,
  TextContainer,
  VisionLinkButton,
  VisionLinkButtonMobile,
} from './CoreVisionSection.styles';

import { Urls } from '@utils';

import { core_vision_section_people } from '@assets';

import { Strings } from '@strings';

const CoreVisionSection = () => {
  return (
    <Outer>
      <Container>
        <InnerContainer>
          <PeopleImage src={core_vision_section_people} alt='core_vision_section_people' />
          <VisionLinkButtonMobile to={Urls.CORE_VALUES}>
            {Strings.CORE_VISION_SECTION_BUTTON}
          </VisionLinkButtonMobile>
        </InnerContainer>
        <TextContainer>
          <H4WithMargin accent bold>
            {Strings.CORE_VISION_SECTION_ACCENT}
          </H4WithMargin>
          <H2WithMargin marginBottom={6}>{Strings.CORE_VISION_SECTION_HEADS[0]}</H2WithMargin>
          <H2WithMargin marginBottom={20} bold>
            {Strings.CORE_VISION_SECTION_HEADS[1]}
          </H2WithMargin>
          <VisionLinkButton to={Urls.CORE_VALUES}>
            {Strings.CORE_VISION_SECTION_BUTTON}
          </VisionLinkButton>
        </TextContainer>
      </Container>
    </Outer>
  );
};

export default CoreVisionSection;
