import LocalizedStrings from 'react-localization';

import { ko } from './ko';
import { en } from './en';

import { getStorage, LanguageStorage } from '@utils';

export enum Language {
  'KO' = 'ko',
  'EN' = 'en',
}

export const Strings = new LocalizedStrings({
  [Language.KO]: ko,
  [Language.EN]: en,
});

export const getInitLanguage = () => {
  const storageLanguage = getStorage<LanguageStorage>('language');

  if (!storageLanguage) {
    return getNavigatorLanguage();
  }
  return storageLanguage;
};

const KOREAN_LOCALE_CODE = ['ko', 'ko-KR'];

const getNavigatorLanguage = () => {
  if (KOREAN_LOCALE_CODE.includes(navigator.language)) {
    return Language.KO;
  }
  return Language.EN;
};

Strings.setLanguage(getInitLanguage());
