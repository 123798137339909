const COUPON = {
  MAIN_IMAGE: {
    TEXT: ['쿠폰 코드 입력 하고', '닥터리슨과 상담해 보세요.'],
  },
  USAGE_GUIDE: {
    TITLE: '쿠폰 사용 방법',
    DESCRIPTION: [
      '이메일과 쿠폰 코드 번호를 입력',
      '쿠폰이 이용권으로 자동 변환',
      '닥터리슨 앱에서 확인',
    ],
  },
  USAGE: {
    TITLE: '쿠폰 사용 하기',
    EMAIL_LABEL: '이메일',
    EMAIL_PLACEHOLDER: '닥터리슨에 가입한 이메일을 입력해 주세요.',
    COUPON_LABEL: '쿠폰 코드',
    COUPON_PLACEHOLDER: '쿠폰 코드 16자리를 입력해 주세요.',
    BUTTON: '쿠폰 사용하기',

    EMAIL_FORMAT_ERROR: '이메일 형식을 확인해 주세요.',
    COUPON_FORMAT_ERROR: '쿠폰 코드 형식을 확인해 주세요.',
    NO_COUPON: '쿠폰 코드가 올바르지 않습니다.',
    UNKNOWN_ERROR: '알 수 없는 에러가 발생했습니다.',
  },
  NOTICE: {
    TITLE: '쿠폰 사용 유의 사항',
    BODY: [
      '이미 사용중인 이용권이 있는 경우, 이용권을 모두 소진하신 후 사용이 가능합니다.',
      '이용권은 유효 기간 내에 사용이 가능합니다. 앱 내 설정에서 확인해 주세요.',
      '사용이 완료된 쿠폰은 재사용 하실 수 없습니다.',
      '동일 프로모션으로 받은 쿠폰은 중복 사용이 불가합니다.',
      '쿠폰 관련 문의는 쿠폰 코드/가입 이메일/에러 문구와 함께 contact@drlisten.ai 로 문의해 주세요.',
    ],
  },
};

export default COUPON;
