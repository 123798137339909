import { Colors } from '@styles';
import { MAX_WIDTH_MOBILE } from '@utils';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${Colors.gray800};
  height: 167px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    padding: 0 20px;
  }
`;

export const SpaceBetweenRow = styled.div`
  width: 100%;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CompanySection = styled.section`
  display: flex;
  flex-direction: column;

  row-gap: 10px;
`;

export const LogoImage = styled.img`
  width: 108px;
  height: 19px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    width: 96px;
    height: 16.12px;
  }
`;

export const AddressRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    flex-wrap: wrap;
    row-gap: 3px;
  }
`;

interface BarProps {
  hiddenInMobile?: boolean;
}
export const Bar = styled.div<BarProps>`
  height: 12px;
  width: 1px;
  background-color: ${Colors.gray700};

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: ${(props) => props.hiddenInMobile && 'none'};
  }
`;

export const TermsRow = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
`;
