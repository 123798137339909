import { Fragment, useContext } from 'react';

import { Text } from '@components';
import {
  AccentText,
  Container,
  DoctorImage,
  H2WithMargin,
  ImageContainer,
  InnerContainer,
  Outer,
  TextCircle,
} from './CollaborativeDevelopmentSection.styles';

import { LanguageContext } from '@context';

import { Colors } from '@styles';
import { collaborative_development_section_doctor } from '@assets';

import { Language, Strings } from '@strings';

const CollaborativeDevelopmentSection = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Outer>
      <Container>
        <InnerContainer>
          <AccentText strong color={Colors.blue400}>
            {Strings.COLLABORATIVE_DEVELOPMENT_SECTION_ACCENT}
          </AccentText>
          <H2WithMargin color={Colors.black}>
            {Strings.COLLABORATIVE_DEVELOPMENT_SECTION_HEADS[0]}
          </H2WithMargin>
          <Text.H2 strong color={Colors.black}>
            {Strings.COLLABORATIVE_DEVELOPMENT_SECTION_HEADS[1]}
          </Text.H2>
        </InnerContainer>
        <ImageContainer>
          {language === Language.KO && (
            <Fragment>
              <TextCircle>{Strings.COLLABORATIVE_DEVELOPMENT_SECTION_CIRCLE_TEXT}</TextCircle>
              <DoctorImage
                src={collaborative_development_section_doctor}
                alt='collaborative_development_section_doctor'
              />
            </Fragment>
          )}
          {language === Language.EN && (
            <Fragment>
              <TextCircle eng>{Strings.COLLABORATIVE_DEVELOPMENT_SECTION_CIRCLE_TEXT}</TextCircle>
              <DoctorImage
                eng
                src={collaborative_development_section_doctor}
                alt='collaborative_development_section_doctor'
              />
            </Fragment>
          )}
        </ImageContainer>
      </Container>
    </Outer>
  );
};

export default CollaborativeDevelopmentSection;
