const Common = {
  Footer: {
    ADDRESS: '5F, 73, Myeongdong-gil, Jung-gu, Seoul, Korea 04538',
    BUSINESS_REGI_NUMBER: 'Business license number 421-88-00471',
    COPYRIGHT: '© Genesis Lab, Inc.  All rights reserved.',
    Terms: {
      SERVICE: 'Terms of Service',
      PRIVACY: 'Privacy Policy',
    },
  },
  DR_LISTEN: 'Dr.Listen',
  APP_STORE: 'App Store',
  GOOGLE_PLAY: 'Google Play',
};

export default Common;
