import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { CommonReducer } from './redux/CommonSlice';

export const CombinedReducers = combineReducers({
  CommonReducer,
});

export const store = configureStore({
  reducer: CombinedReducers,
  middleware: [],
});

export type RootState = ReturnType<typeof store.getState>;

export * from './redux/CommonSlice';
