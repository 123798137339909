import styled from 'styled-components';

import { Text } from '@components/Text/Text';
import { CommonContainer, CommonOuter } from '../common';

import { Colors, FlexColumn, FlexRow, Media } from '@styles';

export const Outer = styled(CommonOuter)`
  background-color: ${Colors.blue50};
`;

export const Container = styled(CommonContainer)`
  padding: 136px 73px;

  ${Media.mobile} {
    flex-direction: column;

    padding: 60px 30px;
  }
`;

export const TextContainer = styled.div`
  ${FlexColumn};
`;

export const ImageContainer = styled.div``;

export const AccentText = styled(Text.H4)`
  margin-bottom: 20px;

  ${Media.mobile} {
    margin-bottom: 16px;
  }
`;

type HeadTextProps = {
  marginBottom?: number;
};
export const HeadText = styled(Text.H2)<HeadTextProps>`
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;

export const DescriptionText = styled(Text.H4)`
  margin-top: 20px;
  margin-bottom: 50px;

  ${Media.mobile} {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

type ChipsProps = {
  marginBottom?: boolean;
};
export const Chips = styled.div<ChipsProps>`
  ${FlexRow};
  align-items: center;

  column-gap: 10px;
  margin-bottom: ${(props) => (props.marginBottom ? 10 : 0)}px;

  ${Media.mobile} {
    column-gap: 7px;
  }
`;

export const PhoneImage = styled.img`
  width: auto;
  height: 527px;

  ${Media.mobile} {
    height: 394px;

    margin-top: 56px;
  }
`;
