export const Header = {
  HEADER_SHARE: '공유하기',
  HEADER_DR_LISTEN: '닥터리슨',
  HEADER_SERVICE: '서비스',
  HEADER_VISION: '핵심가치',
  HEADER_MENU_COUPON_CODE: '쿠폰 코드 입력',
  HEADER_MENU_CONTACT_COMPANY: '기업 도입 문의',
  HEADER_LANGUAGE_SELECTOR_KOR: 'KOR',
  HEADER_LANGUAGE_SELECTOR_ENG: 'ENG',

  // * unused
  HEADER_MENU_INVITATION_EVENT: '친구 초대 이벤트',
};
