import { ResponsiveView } from '@components';
import { SubTitle, Container, Outer, Title, StepRow } from './ManagementSection.styles';
import { StepCircle } from './components';

import { Strings } from '@strings';

const MOBILE_ROW_ITEM_COUNTS = [3, 2];

const ManagementSection = () => {
  const numberOfStep = Strings.SERVICE_MANAGEMENT_SECTION_STEP_TITLE.length;

  return (
    <Outer>
      <Container>
        <SubTitle>{Strings.SERVICE_MANAGEMENT_SECTION_SUB_TITLE}</SubTitle>
        <Title bold>{Strings.SERVICE_MANAGEMENT_SECTION_TITLE}</Title>
        <ResponsiveView.Desktop>
          <StepRow>
            {Array.from({ length: numberOfStep }).map((_, index) => (
              <StepCircle
                key={index}
                title={Strings.SERVICE_MANAGEMENT_SECTION_STEP_TITLE[index]}
                description={Strings.SERVICE_MANAGEMENT_SECTION_STEP_DESCRIPTION[index]}
                fill={index === numberOfStep - 1}
                bridge={index < numberOfStep - 1}
              />
            ))}
          </StepRow>
        </ResponsiveView.Desktop>
        <ResponsiveView.Mobile>
          <StepRow>
            {Array.from({ length: MOBILE_ROW_ITEM_COUNTS[0] }).map((_, index) => (
              <StepCircle
                key={index}
                title={Strings.SERVICE_MANAGEMENT_SECTION_STEP_TITLE[index]}
                description={Strings.SERVICE_MANAGEMENT_SECTION_STEP_DESCRIPTION[index]}
                bridge={index < MOBILE_ROW_ITEM_COUNTS[0] - 1}
              />
            ))}
          </StepRow>
          <StepRow>
            {Array.from({ length: MOBILE_ROW_ITEM_COUNTS[1] }).map((_, index) => (
              <StepCircle
                key={index}
                title={
                  Strings.SERVICE_MANAGEMENT_SECTION_STEP_TITLE[index + MOBILE_ROW_ITEM_COUNTS[0]]
                }
                description={
                  Strings.SERVICE_MANAGEMENT_SECTION_STEP_DESCRIPTION[
                    index + MOBILE_ROW_ITEM_COUNTS[0]
                  ]
                }
                fill={index === MOBILE_ROW_ITEM_COUNTS[1] - 1}
                bridge={index < MOBILE_ROW_ITEM_COUNTS[1] - 1}
              />
            ))}
          </StepRow>
        </ResponsiveView.Mobile>
      </Container>
    </Outer>
  );
};

export default ManagementSection;
