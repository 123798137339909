import styled from 'styled-components';

import { Colors, FlexRow, Fonts, Media } from '@styles';

type ContainerProps = {
  forceDisplayInMobile?: boolean;
};
export const Container = styled.div<ContainerProps>`
  ${FlexRow};
  align-items: center;

  column-gap: 16px;

  ${Media.mobile} {
    display: ${(props) => (props.forceDisplayInMobile ? 'flex' : 'none')};
  }
`;

type LanguageButtonProps = {
  selected?: boolean;
};
export const LanguageButton = styled.button<LanguageButtonProps>`
  all: unset;

  ${Fonts.Regular};
  ${Fonts.body2};
  font-weight: ${(props) => (props.selected ? 700 : 400)};
  color: ${(props) => (props.selected ? Colors.gray700 : Colors.gray300)};

  cursor: pointer;
`;

export const Divider = styled.div`
  width: 1px;
  height: 19px;

  background-color: ${Colors.gray300};
`;
