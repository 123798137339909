import { ListenContainer, NoDecoAnchor, Text } from '@components';
import {
  CompanySection,
  Container,
  LogoImage,
  AddressRow,
  Bar,
  TermsRow,
  SpaceBetweenRow,
  Column,
} from './Footer.styles';

import { NotionUrl } from '@utils';

import { Colors } from '@styles';
import { logo_footer } from '@assets';

import { Strings } from '@strings';

const Footer = () => {
  return (
    <Container>
      <ListenContainer>
        <Column>
          <SpaceBetweenRow>
            <LogoImage src={logo_footer} alt='DrListenLogo' />
            <TermsRow>
              <NoDecoAnchor href={NotionUrl.SERVICE_TERMS} target='_blank'>
                <Text.Footer color={Colors.gray500}>
                  {Strings.Common.Footer.Terms.SERVICE}
                </Text.Footer>
              </NoDecoAnchor>
              <Bar />
              <NoDecoAnchor href={NotionUrl.PRIVACY_TERMS} target='_blank'>
                <Text.Footer color={Colors.gray500} bold>
                  {Strings.Common.Footer.Terms.PRIVACY}
                </Text.Footer>
              </NoDecoAnchor>
            </TermsRow>
          </SpaceBetweenRow>
          <CompanySection>
            <AddressRow>
              <Text.Footer color={Colors.gray500}>{Strings.Common.Footer.ADDRESS}</Text.Footer>
              <Bar hiddenInMobile />
              <Text.Footer color={Colors.gray500}>
                {Strings.Common.Footer.BUSINESS_REGI_NUMBER}
              </Text.Footer>
            </AddressRow>
            <Text.Footer color={Colors.gray500}>{Strings.Common.Footer.COPYRIGHT}</Text.Footer>
          </CompanySection>
        </Column>
      </ListenContainer>
    </Container>
  );
};

export default Footer;
