import { DontShowBannerAgainDate, Test, LanguageStorage } from './types';

type StorageData = DontShowBannerAgainDate | Test | LanguageStorage;

export const setStorage = <T extends StorageData>(key: T['keyName'], value: T['type']) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getStorage = <T extends StorageData>(key: T['keyName']) => {
  const data = localStorage.getItem(key);
  if (!data) return null;

  return JSON.parse(data) as T['type'];
};
