import { ResponsiveView, Text } from '@components';

import { TextCircle } from './components';
import { CircleRow, Container } from './DoNotWorrySection.styles';

import { Strings } from '@strings';

const DoNotWorrySection = () => {
  return (
    <Container>
      <Text.H2 style={{ textAlign: 'center' }}>{Strings.DO_NOT_WORRY_SECTION_HEADS[0]}</Text.H2>
      <Text.H2 strong style={{ textAlign: 'center' }}>
        {Strings.DO_NOT_WORRY_SECTION_HEADS[1]}
      </Text.H2>
      <CircleRow>
        {Strings.DO_NOT_WORRY_SECTION_CIRCLE_TEXTS.map((text, index) => (
          <TextCircle key={index} text={text} />
        ))}
      </CircleRow>
      <Text.H2>{Strings.DO_NOT_WORRY_SECTION_FOOTERS[0]}</Text.H2>
      <ResponsiveView.Desktop>
        <Text.H2 strong>{Strings.DO_NOT_WORRY_SECTION_FOOTERS[1]}</Text.H2>
      </ResponsiveView.Desktop>
      <ResponsiveView.Mobile>
        <Text.H2 strong style={{ textAlign: 'center' }}>
          {Strings.DO_NOT_WORRY_SECTION_FOOTERS_MOBILE[1]}
        </Text.H2>
        <Text.H2 strong style={{ textAlign: 'center' }}>
          {Strings.DO_NOT_WORRY_SECTION_FOOTERS_MOBILE[2]}
        </Text.H2>
      </ResponsiveView.Mobile>
    </Container>
  );
};

export default DoNotWorrySection;
