import { icon_notice } from '@assets';
import { Strings } from '@strings';
import { Container, NoticeIcon, TextList, Text, Title } from './NoticeSection.styles';

const NoticeSection = () => {
  return (
    <Container>
      <Title>
        <NoticeIcon src={icon_notice} alt='noticeIcon' />
        {Strings.Coupon.NOTICE.TITLE}
      </Title>
      <TextList>
        {Strings.Coupon.NOTICE.BODY.map((each, idx) => (
          <Text key={idx}>{each}</Text>
        ))}
      </TextList>
    </Container>
  );
};

export default NoticeSection;
