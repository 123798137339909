import styled from 'styled-components';
import { Colors, Fonts } from '@styles';
import { MAX_WIDTH_MOBILE } from '@utils';

export const Container = styled.section`
  margin: 0 auto;
  margin-top: 100px;

  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 30px;
  background-color: ${Colors.gray80};
  border-radius: 10px;

  margin-bottom: 120px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    width: 100vw;
    margin-bottom: 0px;
    border-radius: 0px;
  }
`;

export const NoticeIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 6px;
`;

export const Title = styled.h3`
  ${Fonts.head3};
  ${Fonts.Bold};

  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${Colors.gray800};
  margin-bottom: 20px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const TextList = styled.ul`
  margin: 0;
  padding-left: 1.5em;

  list-style: disc;
`;

export const Text = styled.li`
  ${Fonts.Regular};
  font-size: 17px;
  line-height: 30px;
  letter-spacing: -0.7px;
  color: ${Colors.gray700};

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    font-size: 16px;
    line-height: 30px;
  }
`;
