// components
import { TitleWithUnderline } from '@pages/home/components';
import { Container, BodyText, BodyContainer } from './AboutServiceSection.styles';
// strings
import { Strings } from '@strings';
import { getStyledText } from '@utils/styles';
import { Colors } from '@styles';

const AboutServiceSection = () => {
  return (
    <Container>
      <TitleWithUnderline>{Strings.Invitation.AboutService.TITLE}</TitleWithUnderline>
      <BodyContainer>
        <BodyText>
          {getStyledText(Strings.Invitation.AboutService.BODY[0], {
            color: Colors.blue400,
            bold: true,
          })}
        </BodyText>
        <BodyText>{Strings.Invitation.AboutService.BODY[1]}</BodyText>
      </BodyContainer>
    </Container>
  );
};

export default AboutServiceSection;
