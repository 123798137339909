import styled from 'styled-components';
import { Colors, Fonts } from '@styles';
import { MAX_WIDTH_MOBILE } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  ${Fonts.head4};
  ${Fonts.Bold};
  font-weight: 500;
  margin-bottom: 10px;
`;

export const StyledInput = styled.input`
  ${Fonts.body1};
  ${Fonts.Regular}
  width: 500px;
  height: 65px;
  border-radius: 10px;
  padding: 0px 20px;
  background-color: ${Colors.white};
  border: 2px solid ${Colors.gray200};

  &::placeholder {
    color: ${Colors.gray500};
  }
  &:focus {
    outline: none;
    border: 2px solid ${Colors.blue500};
  }

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    width: 335px;
  }
`;

export const ErrorMessage = styled.span`
  color: ${Colors.red400};
  margin-top: 10px;
  font-size: 14px;
`;
