import styled from 'styled-components';

import { Colors, Media } from '@styles';
import { CONTENT_CONTAINER_WIDTH } from '@utils';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: ${CONTENT_CONTAINER_WIDTH}px;
  padding-top: 159px;
  padding-bottom: 164px;
  margin: 0 auto;

  background-color: ${Colors.white};

  ${Media.mobile} {
    width: 100%;
    padding: 60px 0px;
  }
`;

export const CircleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  column-gap: 40.45px;
  margin-top: 104px;
  margin-bottom: 100.45px;

  ${Media.mobile} {
    justify-content: center;

    row-gap: 16px;
    margin: 56px 0px;
  }
`;
