import styled from 'styled-components';

import { Text } from '@components/Text/Text';
import { CircleCSS, Colors, Media } from '@styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 340.55px;
  height: 340.55px;

  ${Media.mobile} {
    ${CircleCSS(255 / 2)};
  }
`;

export const Circle = styled.div`
  position: absolute;

  width: 340.55px;
  height: 340.55px;
  border-radius: ${340.55 / 2}px;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(18.57deg, #62a7fb 37.48%, #3c7ae5 62.67%);
  filter: blur(12.5px);

  ${Media.mobile} {
    ${CircleCSS(255 / 2)};
  }
`;

export const Contents = styled(Text.H3)`
  z-index: 1;
  text-align: center;

  color: ${Colors.white};

  ${Media.mobile} {
    font-size: 17.97px;
    line-height: 21.45px;
    letter-spacing: -1.5px;
  }
`;
