export const Colors = {
  white: '#FFFFFF',
  black: '#11202D',
  transparent: 'rgba(0,0,0,0)',
  dimmedBlack: 'rgba(0, 0, 0, 0.8)',

  gray900: '#283642',
  gray800: '#364858',
  gray700: '#475F74',
  gray600: '#516678',
  gray500: '#8A9AA8',
  gray500_opacity20: 'rgba(138, 154, 168, 0.2)',
  gray400: '#ABBBC9',
  gray300: '#C4D0DA',
  gray300_opacity20: 'rgba(196, 208, 218, 0.2)',
  gray300_opaicty30: 'rgba(196, 208, 218, 0.3)',
  gray200: '#D8E0E7',
  gray100: '#E4EBF1',
  gray80: '#F2F6F9',
  gray50: '#F7F9FB',

  blue900: '#0A347C',
  blue600: '#1C53B1',
  blue600_opacity30: 'rgba(28, 83, 177, 0.3)',
  blue500: '#2765D2',
  // * primary
  blue400: 'rgba(60, 122, 229, 1)', // #3C7AE5
  blue400_opactiy15: 'rgba(60, 122, 229, 0.15)',
  blue400_opacity20: 'rgba(60, 122, 229, 0.2)',
  blue400_opacity30: 'rgba(60, 122, 229, 0.3)',
  blue400_opacity40: 'rgba(60, 122, 229, 0.4)',
  blue400_opacity60: 'rgba(60, 122, 229, 0.6)',
  blue400_opacity80: 'rgba(60, 122, 229, 0.8)',
  blue300: '#5D94F1',
  blue200: '#74A8FF',
  blue100: '#9CC1FF',
  blue80: '#DEEAFF',
  blue50: '#F2F7FF',

  red600: '#DD3C2A',
  red400: '#FA5B4A',
  red400_opacity40: 'rgba(250, 91, 74, 0.4)',
  red300: '#FE6A54',

  yellow900: '#793E1D',
  yellow300: '#ffc929',

  pink: '#FF406E',
};
