import { Media } from '@styles';
import styled from 'styled-components';

const Desktop = styled.div`
  ${Media.mobile} {
    display: none;
  }
`;
const Mobile = styled.div`
  display: none;
  ${Media.mobile} {
    display: block;
  }
`;

export const ResponsiveView = {
  Desktop,
  Mobile,
};
