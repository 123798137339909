import styled from 'styled-components';
// styles
import { Colors, Fonts, Responsive } from '@styles';

export const Container = styled.section`
  background-color: ${Colors.blue400};
  padding-top: 40px;
  padding-bottom: 52.79px;
  row-gap: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (${Responsive.MOBILE}) {
    display: none;
  }
`;

export const Buttons = styled.div`
  column-gap: 30px;

  display: flex;
  flex-direction: row;
`;

export const StoreIcon = styled.img`
  width: 31.58px;
  height: 31.58px;
`;

export const StoreButton = styled.button`
  ${Fonts.Medium};
  border: none;

  background-color: ${Colors.white};
  color: ${Colors.gray800};

  width: 223.68px;
  column-gap: 13.16px;
  padding: 26.32px;
  border-radius: 13.16px;
  font-size: 22.37px;
  line-height: 30.26px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

// * Responsive View

export const MobileContainer = styled.section`
  display: none;

  @media screen and (${Responsive.MOBILE}) {
    margin-bottom: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const MobileDownloadButton = styled.button`
  ${Fonts.Bold};
  border: none;

  color: ${Colors.white};
  background-color: ${Colors.blue400};

  width: 335px;
  height: 56px;
  border-radius: 12px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.7px;

  cursor: pointer;
`;
