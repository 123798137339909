import { HTMLAttributes, ReactNode } from 'react';
import { StyledButton } from './Button.styles';

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  styleDisabled?: boolean;
}

const Button = (props: ButtonProps) => {
  const { children, styleDisabled, ...rest } = props;

  return (
    <StyledButton styleDisabled={styleDisabled} {...rest}>
      {children}
    </StyledButton>
  );
};

export default Button;
