import styled from 'styled-components';

import { Text } from '@components/Text/Text';
import { CommonContainer, CommonOuter } from '../common';

import { Colors, Flex, Media } from '@styles';

export const Outer = styled(CommonOuter)`
  background: linear-gradient(92.93deg, #62a7fb 35.45%, #3c7ae5 66.95%);
`;

export const Container = styled(CommonContainer)`
  padding: 118px 0px;

  ${Media.mobile} {
    align-items: flex-start;
    flex-direction: column;

    padding: 60px 30px;
  }
`;

export const TextContainer = styled.div`
  ${Flex.Column};
`;

export const HeadText = styled(Text.H2)`
  margin-bottom: 50px;

  color: ${Colors.white};

  ${Media.mobile} {
    margin-bottom: 16px;

    font-size: 24px;
    line-height: 29px;
  }
`;

export const BodyTexts = styled.div`
  ${Flex.Column};

  row-gap: 20px;
`;

export const BodyText = styled(Text.H3)`
  color: ${Colors.white};

  ${Media.mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const ImageContainer = styled.div`
  ${Media.mobile} {
    align-self: flex-end;

    margin-top: 16px;
  }
`;

export const CompassImage = styled.img`
  width: 550px;
  opacity: 0.85;
  mix-blend-mode: screen;

  ${Media.mobile} {
    max-width: 300px;
    width: 100%;

    transform: translateX(30px);
  }
`;
