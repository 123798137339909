import { Contents, Circle, Container } from './TextCircle.styles';

type TextCircleProps = {
  text?: string;
};
const TextCircle = (props: TextCircleProps) => {
  const { text } = props;

  return (
    <Container>
      <Circle />
      <Contents bold>{text}</Contents>
    </Container>
  );
};

export default TextCircle;
