import {
  TextContainer,
  DescriptionText,
  Outer,
  Container,
  HeadText,
  NoticeText,
  ImageContainer,
  VennDiagramImage,
  HolisticText,
  MentalText,
  PhysicalText,
  SocialText,
} from './AccuracySection.styles';

import { accuracy_section_venn_diagram } from '@assets';

import { Strings } from '@strings';

const AccuracySection = () => {
  return (
    <Outer>
      <Container>
        <TextContainer>
          <HeadText enableMarginBottom>{Strings.VISION_ACCURACY_SECTION_HEADS[0]}</HeadText>
          <HeadText bold>{Strings.VISION_ACCURACY_SECTION_HEADS[1]}</HeadText>
          <DescriptionText>{Strings.VISION_ACCURACY_SECTION_DESCRIPTION}</DescriptionText>
          <NoticeText>{Strings.VISION_ACCURACY_SECTION_NOTICE}</NoticeText>
        </TextContainer>
        <ImageContainer>
          <VennDiagramImage
            src={accuracy_section_venn_diagram}
            alt='accuracy_section_venn_diagram'
          />
          <MentalText>{Strings.VISION_ACCURACY_SECTION_MENTAL}</MentalText>
          <PhysicalText>{Strings.VISION_ACCURACY_SECTION_PHYSICAL}</PhysicalText>
          <SocialText>{Strings.VISION_ACCURACY_SECTION_SOCIAL}</SocialText>
          <HolisticText>{Strings.VISION_ACCURACY_SECTION_HOLISTIC}</HolisticText>
        </ImageContainer>
      </Container>
    </Outer>
  );
};

export default AccuracySection;
