import { Colors, Fonts, Responsive } from '@styles';
import styled from 'styled-components';

export const Container = styled.section`
  row-gap: 40px;
  margin-top: 100px;
  margin-bottom: 80px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (${Responsive.MOBILE}) {
    row-gap: 20px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
`;

export const BodyContainer = styled.div`
  row-gap: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (${Responsive.MOBILE}) {
    row-gap: 10px;
    margin: 0 20px;
  }
`;

export const BodyText = styled.span`
  ${Fonts.Medium};

  color: ${Colors.gray600};

  font-size: 17px;
  line-height: 23px;
  letter-spacing: -1px;

  @media screen and (${Responsive.MOBILE}) {
    ${Fonts.Regular};

    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.7px;
  }
`;
