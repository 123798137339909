const { REACT_APP_LEVEL, REACT_APP_API_ENDPOINT_DEV, REACT_APP_API_ENDPOINT_PROD } = process.env;

export enum StoreType {
  GOOGLE_PLAY = 'GOOGLE_PLAY',
  APP_STORE = 'APP_STORE',
}

export const SearchParamNames = {
  SEARCH_PARAM_APP_VIEW: 'appview',
};

export const API_ENDPOINT =
  REACT_APP_LEVEL === 'dev' ? REACT_APP_API_ENDPOINT_DEV : REACT_APP_API_ENDPOINT_PROD;

export const MAX_WIDTH_MOBILE = 767;
export const INVITATION_CODE_LENGTH = 8;
export const COUPON_CODE_LENGTH = 16;

export const CONTENT_CONTAINER_WIDTH = 1170;

export const FontNames = {
  SpoqaHanSansNeo: {
    Bold: 'SpoqaHanSansNeoBold',
    Medium: 'SpoqaHanSansNeoMedium',
    Regular: 'SpoqaHanSansNeoRegular',
  },
};

export const AppScheme = {
  IOS: 'com.genesislab.drlisn://',
  ANDROID: 'com.genesislab.drlisn://',
};

export const StoreUrl = {
  [StoreType.APP_STORE]: 'https://apps.apple.com/kr/app/dr-listen/id1608846704',
  [StoreType.GOOGLE_PLAY]: 'https://play.google.com/store/apps/details?id=com.genesislab.drlisn',
};

export const NotionUrl = {
  SERVICE_TERMS: 'https://genesislab.notion.site/10caad0dfe33805cab23dda8003b9f01',
  PRIVACY_TERMS: 'https://genesislab.notion.site/817e7e4445114b8fb7d86aff42980bc1',

  COMPANY_CONTACT: 'https://genesislab.notion.site/for-business-a962ee4a2dd34df2b2eb1e20b6ac5002',
};
