import { ReactNode } from 'react';
import { FontNames } from '@utils';

interface AddStyleOptions {
  color?: string;
  bold?: boolean;
}

export const getStyledText = (text: string, options: AddStyleOptions) => {
  const { color, bold } = options;

  const cTagRegexp = /<c>([^>]*)<\/c>/g;

  const splitted = text.split(cTagRegexp);

  const result: ReactNode[] = [];
  for (let i = 0; i < splitted.length - 1; i++) {
    if (i % 2 === 1) {
      result.push(
        <span
          key={i}
          style={{ color, fontFamily: bold ? FontNames.SpoqaHanSansNeo.Bold : 'inherit' }}
        >
          {splitted[i]}
        </span>
      );
    } else {
      result.push(splitted[i]);
    }
  }
  result.push(splitted.slice(-1));

  return result;
};
