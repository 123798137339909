import styled, { css } from 'styled-components';

import { MAX_WIDTH_MOBILE } from '@utils';

import { Colors, Fonts, Media } from '@styles';

import { Language } from '@strings';

export interface CommonProps {
  color?: string;
  bold?: boolean;
  medium?: boolean;
  regular?: boolean;

  language: Language;
}

export interface H2StyledProps extends CommonProps {
  strong?: boolean;
}
export const StyledH2 = styled.h2<H2StyledProps>`
  ${(props) => (props.strong ? Fonts.Bold : Fonts.Regular)};
  ${(props) => props.bold && Fonts.Bold};
  ${(props) => props.medium && Fonts.Medium};
  ${(props) => props.regular && Fonts.Regular};

  color: ${(props) => props.color ?? Colors.black};
  font-size: 44px;
  line-height: 55.09px;

  letter-spacing: ${(props) => (props.language === Language.KO ? -2.5 : 0)}px;

  white-space: pre-wrap;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    font-size: 28px;
    line-height: 35.06px;

    margin: 3px 0;
  }
`;

export interface H3StyledProps extends CommonProps {
  strong?: boolean;
}
export const StyledH3 = styled.h3<H3StyledProps>`
  ${(props) => (props.strong ? Fonts.Bold : Fonts.Regular)};
  ${(props) => props.bold && Fonts.Bold};
  ${(props) => props.medium && Fonts.Medium};
  ${(props) => props.regular && Fonts.Regular};

  color: ${(props) => props.color ?? Colors.black};
  font-size: 24px;
  line-height: 28.64px;
  letter-spacing: ${(props) => (props.language === Language.KO ? -2 : 0)}px;

  white-space: pre-wrap;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    font-size: 15px;
    line-height: 23px;
    letter-spacing: ${(props) => (props.language === Language.KO ? -0.5 : 0)}px;
  }
`;

export interface H4StyledProps extends CommonProps {
  strong?: boolean;
  accent?: boolean;
}
export const StyledH4 = styled.h4<H4StyledProps>`
  ${(props) => (props.strong ? Fonts.Bold : Fonts.Regular)};
  ${(props) => props.bold && Fonts.Bold};
  ${(props) => props.medium && Fonts.Medium};
  ${(props) => props.regular && Fonts.Regular};

  color: ${(props) => props.color ?? (props.accent ? Colors.blue400 : Colors.black)};
  font-size: 19px;
  line-height: 30px;
  letter-spacing: ${(props) => (props.language === Language.KO ? -1 : 0)}px;

  white-space: pre-wrap;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    font-size: 17px;
    line-height: 23px;

    ${(props) =>
      !props.accent &&
      css`
        font-size: 15px;
        line-height: 25px;
      `};
  }
`;

export const StyledBody1 = styled.span<CommonProps>`
  ${(props) => props.bold && Fonts.Bold};
  ${(props) => props.medium && Fonts.Medium};
  ${(props) => props.regular && Fonts.Regular};

  font-size: 17px;
  line-height: 23px;
  letter-spacing: ${(props) => (props.language === Language.KO ? -1 : 0)}px;

  white-space: pre-wrap;

  ${Media.mobile} {
    letter-spacing: ${(props) => (props.language === Language.KO ? -0.7 : 0)}px !important;
  }
`;

export interface Body2StyledProps extends CommonProps {
  strong?: boolean;
}
export const StyledBody2 = styled.span<Body2StyledProps>`
  ${(props) => (props.strong ? Fonts.Bold : Fonts.Regular)};
  ${(props) => props.bold && Fonts.Bold};
  ${(props) => props.medium && Fonts.Medium};
  ${(props) => props.regular && Fonts.Regular};

  color: ${(props) => props.color ?? Colors.black};
  font-size: 15px;
  line-height: 18.78px;
  letter-spacing: ${(props) => (props.language === Language.KO ? -0.5 : 0)}px;

  white-space: pre-wrap;
`;

export const StyledFooter = styled.span<CommonProps>`
  ${Fonts.Regular};
  ${(props) => props.bold && Fonts.Bold};
  ${(props) => props.medium && Fonts.Medium};
  ${(props) => props.regular && Fonts.Regular};

  color: ${(props) => props.color ?? Colors.black};
  font-size: 15px;
  line-height: 18.78px;
  letter-spacing: ${(props) => (props.language === Language.KO ? -0.5 : 0)}px;

  white-space: pre-wrap;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    font-size: 13px;
    line-height: 16.28px;
  }
`;
