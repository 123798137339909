import { useContext } from 'react';

import {
  AccentText,
  Container,
  DescriptionText,
  HeadText,
  ImageContainer,
  Outer,
  PhoneImage,
  PhoneImageMobile,
  TextContainer,
} from './NoteSection.styles';

import { LanguageContext } from '@context';

import {
  note_section_phone,
  note_section_phone_eng,
  note_section_phone_mobile,
  note_section_phone_mobile_eng,
} from '@assets';

import { Language, Strings } from '@strings';

const NoteSection = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Outer>
      <Container>
        <TextContainer>
          <AccentText bold>{Strings.SERVICE_NOTE_SECTION_ACCENT}</AccentText>
          <HeadText marginBottom={6}>{Strings.SERVICE_NOTE_SECTION_HEADS[0]}</HeadText>
          <HeadText bold>{Strings.SERVICE_NOTE_SECTION_HEADS[1]}</HeadText>
          <DescriptionText>{Strings.SERVICE_NOTE_SECTION_DESCRIPTION}</DescriptionText>
        </TextContainer>
        <ImageContainer>
          {language === Language.KO && (
            <PhoneImage src={note_section_phone} alt='note_section_phone' />
          )}
          {language === Language.EN && (
            <PhoneImage src={note_section_phone_eng} alt='note_section_phone' />
          )}
          {language === Language.KO && (
            <PhoneImageMobile src={note_section_phone_mobile} alt='note_section_phone_mobile' />
          )}
          {language === Language.EN && (
            <PhoneImageMobile src={note_section_phone_mobile_eng} alt='note_section_phone_mobile' />
          )}
        </ImageContainer>
      </Container>
    </Outer>
  );
};

export default NoteSection;
