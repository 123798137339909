import styled from 'styled-components';

import { Text } from '@components/Text/Text';
import { CommonContainer, CommonOuter } from '../common';

import { Colors, Flex, Media } from '@styles';

export const Outer = styled(CommonOuter)`
  background-color: ${Colors.blue50};
`;

export const Container = styled(CommonContainer)`
  padding: 167px 73px;

  ${Media.mobile} {
    align-items: flex-start;
    flex-direction: column;

    padding: 60px 30px;
  }
`;

export const TextContainer = styled.div`
  ${Flex.Column};
`;

export const ImageContainer = styled.div`
  ${Media.mobile} {
    margin-top: 89.15px;
  }
`;

export const PhoneImage = styled.img`
  width: 354px;

  ${Media.mobile} {
    max-width: 354px;
    width: 100%;
  }
`;

export const AccentText = styled(Text.H4)`
  margin-bottom: 20px;

  color: ${Colors.blue400};

  ${Media.mobile} {
    margin-bottom: 16px;
  }
`;

type HeadTextProps = {
  marginBottom?: number;
  enableLineBreakOnlyMobile?: boolean;
};
export const HeadText = styled(Text.H2)<HeadTextProps>`
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;

  white-space: ${(props) => (props.enableLineBreakOnlyMobile ? 'nowrap' : 'pre-wrap')};

  ${Media.mobile} {
    white-space: pre-wrap;
  }
`;

export const DescriptionText = styled(Text.H4)`
  margin-top: 20px;

  color: ${Colors.gray500};

  ${Media.mobile} {
    margin-top: 16px;
  }
`;
