import { useContext } from 'react';

import { Text } from '@components';
import {
  Container,
  H2WithMargin,
  H4WithMargin,
  InnerContainer,
  Outer,
  PhoneImage,
} from './EasyUseSection.styles';

import { LanguageContext } from '@context';

import { Colors } from '@styles';
import { easy_use_section_phone, easy_use_section_phone_eng } from '@assets';

import { Language, Strings } from '@strings';

const EasyUseSection = () => {
  const { language } = useContext(LanguageContext);

  const getImage = () => {
    switch (language) {
      case Language.KO:
        return easy_use_section_phone;
      case Language.EN:
      default:
        return easy_use_section_phone_eng;
    }
  };

  return (
    <Outer>
      <Container>
        <InnerContainer>
          <H4WithMargin strong color={Colors.blue400}>
            {Strings.EASY_USE_SECTION_ACCENT}
          </H4WithMargin>
          <H2WithMargin>{Strings.EASY_USE_SECTION_HEADS[0]}</H2WithMargin>
          <Text.H2 strong>{Strings.EASY_USE_SECTION_HEADS[1]}</Text.H2>
        </InnerContainer>
        <InnerContainer>
          <PhoneImage src={getImage()} alt='easy_use_section_phone' />
        </InnerContainer>
      </Container>
    </Outer>
  );
};

export default EasyUseSection;
