import styled from 'styled-components';

import { Text } from '@components/Text/Text';

import { MAX_WIDTH_MOBILE } from '@utils';

import { Colors, Fonts, Media } from '@styles';
import { store_button_section_background, store_button_section_background_mobile } from '@assets';

export const Container = styled.div`
  height: 600px;

  background: url(${store_button_section_background});
  background-size: cover;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    height: 350px;
    overflow-x: hidden;

    background: url(${store_button_section_background_mobile});
    background-size: cover;
  }
`;

export const LeftSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const SubHead = styled(Text.H3)`
  margin-top: 155px;
  margin-bottom: 20px;

  color: ${Colors.white};

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    text-align: center;

    margin-top: 60px;
    margin-bottom: 10px;

    ${Fonts.Medium};
  }
`;

export const Head = styled(Text.H2)`
  color: ${Colors.white};

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    text-align: center;

    margin-bottom: 6px;

    font-size: 24px;
    line-height: 28.64px;
    letter-spacing: -2px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 51px;
  column-gap: 20px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    align-self: center;

    margin-top: 24px;

    z-index: 1;
  }
`;

export const RightSection = styled.section`
  display: flex;
  position: relative;
  flex: 1;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    position: absolute;
    pointer-events: none;

    right: 0;
    background-color: blue;
  }
`;

export const MockPhoneImage = styled.img`
  position: absolute;
  width: 640px;
  height: 629px;
  margin-top: 120px;
  left: 95.66px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: none;
  }
`;

export const Circle = styled.div`
  position: absolute;
  clip: rect(0px, 600px, 295px, 0px);

  top: 305px;
  left: -15px;
  width: 600px;
  height: 600px;
  border-radius: 300px;

  background: linear-gradient(135deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);

  ${Media.mobile} {
    display: none;
  }
`;

export const CircleImageMobile = styled.img`
  display: none;

  ${Media.mobile} {
    display: block;
    position: absolute;

    top: 97px;
    right: 0px;

    height: 130px;

    z-index: 0;
  }
`;
