import { Colors, Fonts, Responsive } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 100px;
  row-gap: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (${Responsive.MOBILE}) {
    margin-bottom: 40px;
    row-gap: 20px;
  }
`;

export const GuideContainer = styled.div`
  column-gap: 58px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (${Responsive.MOBILE}) {
    row-gap: 40px;

    flex-direction: column;
  }
`;

export const Guide = styled.div`
  row-gap: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GuideImage = styled.img`
  outline: 1px solid ${Colors.gray300};

  border-radius: 16px;
  width: 200px;
  height: 440.54px;
`;

export const GuideDescriptionRow = styled.span`
  column-gap: 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const GuideDescription = styled.span`
  ${Fonts.Medium};

  color: ${Colors.gray600};

  width: 191px;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -1px;
`;

export const NumberLabel = styled.div`
  ${Fonts.Bold};

  color: ${Colors.blue400};
  background-color: ${Colors.blue80};

  height: 28px;
  width: 28px;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowContainer = styled.div`
  border: 1px solid ${Colors.gray100};
  background-color: ${Colors.white};

  width: 44px;
  height: 44px;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowImage = styled.img`
  width: 24px;
  height: 24px;

  @media screen and (${Responsive.MOBILE}) {
    transform: rotate(90deg);
  }
`;
