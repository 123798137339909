import { useContext } from 'react';

import { Container, Divider, LanguageButton } from './LanguageSelector.styles';

import { LanguageContext } from '@context';

import { Language, Strings } from '@strings';

type LanguageSelectorProps = {
  forceDisplayInMobile?: boolean;
};
const LanguageSelector = (props: LanguageSelectorProps) => {
  const { forceDisplayInMobile } = props;
  const { language, setLanguage } = useContext(LanguageContext);

  const setLocale = (language: Language) => {
    setLanguage(language);
  };

  return (
    <Container forceDisplayInMobile={forceDisplayInMobile}>
      <LanguageButton onClick={() => setLocale(Language.KO)} selected={language === Language.KO}>
        {Strings.HEADER_LANGUAGE_SELECTOR_KOR}
      </LanguageButton>
      <Divider />
      <LanguageButton onClick={() => setLocale(Language.EN)} selected={language === Language.EN}>
        {Strings.HEADER_LANGUAGE_SELECTOR_ENG}
      </LanguageButton>
    </Container>
  );
};

export default LanguageSelector;
