import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// utils
import { checkIsMobileResponsiveView } from '@utils';
import { CommonActions, ViewType } from '@store';

export const useViewTypeUpdater = () => {
  // TODO Debounce 적용
  const dispatch = useDispatch();

  const onResize = useCallback(() => {
    const viewType = checkIsMobileResponsiveView() ? ViewType.MOBILE : ViewType.DESKTOP;
    dispatch(CommonActions.setViewType(viewType));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);
};
