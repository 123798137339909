import { Fragment, useContext } from 'react';

import { StoreButton, ListenContainer } from '@components';
import {
  ButtonContainer,
  Circle,
  CircleImageMobile,
  Container,
  Head,
  SubHead,
  LeftSection,
  MockPhoneImage,
  RightSection,
} from './StoreButtonSection.styles';

// import { useCommonSlice } from '@hooks';

// import { checkIsMobileResponsiveView } from '@utils';
import { StoreType } from '@utils';

import {
  mockup_home_screen,
  mockup_home_screen_eng,
  store_button_section_circle_mobile,
} from '@assets';
// import {
//   HEADER_HEIGHT_DESKTOP,
//   HEADER_HEIGHT_MOBILE,
// } from '../CouponEventBanner/CouponEventBanner.styles';

import { Language, Strings } from '@strings';
import { LanguageContext } from '@context';

const StoreButtonSection = () => {
  // const { eventBanner } = useCommonSlice();
  const { language } = useContext(LanguageContext);

  // const marginHeight = checkIsMobileResponsiveView() ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP;

  return (
    <Fragment>
      {/* {eventBanner.isShown && <div style={{ height: marginHeight }} />} */}
      <Container>
        <ListenContainer>
          <LeftSection>
            <SubHead>{Strings.HOME_STORE_BUTTON_SECTION_HEAD3}</SubHead>
            <Head>{Strings.HOME_STORE_BUTTON_SECTION_HEAD1[0]}</Head>
            <Head strong>
              <strong>{Strings.HOME_STORE_BUTTON_SECTION_HEAD1[1]}</strong>
            </Head>
            <ButtonContainer>
              <StoreButton storeType={StoreType.APP_STORE} />
              <StoreButton storeType={StoreType.GOOGLE_PLAY} />
            </ButtonContainer>
          </LeftSection>
          <RightSection>
            <Circle />
            <CircleImageMobile src={store_button_section_circle_mobile} />
            {language === Language.KO && (
              <MockPhoneImage src={mockup_home_screen} alt='mockup_home_screen' />
            )}
            {language === Language.EN && (
              <MockPhoneImage src={mockup_home_screen_eng} alt='mockup_home_screen_eng' />
            )}
          </RightSection>
        </ListenContainer>
      </Container>
    </Fragment>
  );
};

export default StoreButtonSection;
