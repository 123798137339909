import { css } from 'styled-components';

export const CircleCSS = (radius: number) => {
  return css`
    width: ${radius * 2}px;
    height: ${radius * 2}px;
    border-radius: ${radius}px;
  `;
};

export const FlexRow = css`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Flex = {
  Row: FlexRow,
  Column: FlexColumn,
};
