import styled from 'styled-components';

import { Text } from '@components/Text/Text';

import { CommonContainer, CommonLinkButton, CommonOuter } from '../common';

import { Colors, Media } from '@styles';

export const Outer = styled(CommonOuter)`
  background-color: ${Colors.white};
`;

export const Container = styled(CommonContainer)`
  padding: 137px 73px;

  ${Media.mobile} {
    flex-direction: column-reverse;
    align-items: flex-start;

    padding: 60px 30px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MockUpPhoneImage = styled.img`
  height: 527px;

  ${Media.mobile} {
    width: 100%;
    height: auto;

    margin-top: ${56 - 20}px;
  }
`;

export const H4WithMargin = styled(Text.H4)`
  margin-bottom: 20px;
`;

type H2WithMarginBottomProps = {
  marginBottom: number;
};
export const H2WithMarginBottom = styled(Text.H2)<H2WithMarginBottomProps>`
  margin-bottom: ${(props) => props.marginBottom}px;
`;

export const ServiceLinkButton = styled(CommonLinkButton)`
  ${Media.mobile} {
    display: none;
  }
`;

export const ServiceLinkButtonMobile = styled(CommonLinkButton)`
  display: none;

  ${Media.mobile} {
    display: inherit;

    width: 100%;
    margin-top: 56px;
  }
`;
