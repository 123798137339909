import { CONTENT_CONTAINER_WIDTH, MAX_WIDTH_MOBILE } from '@utils';
import styled from 'styled-components';

export const OuterContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface InnerContainerProps {
  wrapReverse?: boolean;
}
export const InnerContainer = styled.section<InnerContainerProps>`
  width: ${CONTENT_CONTAINER_WIDTH}px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    flex-wrap: ${(props) => (props.wrapReverse ? 'wrap-reverse' : 'wrap')};
    align-items: center;
    justify-content: center;
  }
`;
