import { ReactNode } from 'react';
import { Container, Title, TitleProps, Underline } from './TitleWithUnderline.styles';

interface TitleWithUnderlineProps extends TitleProps {
  children: ReactNode;
}

const TitleWithUnderline = (props: TitleWithUnderlineProps) => {
  return (
    <Container>
      <Title color={props.color}>{props.children}</Title>
      <Underline />
    </Container>
  );
};

export default TitleWithUnderline;
