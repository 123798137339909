import { StoreButton } from '@components';
import { Head, Buttons, Container, Outer } from './FooterStoreButtonSection.styles';

import { StoreType } from '@utils';

import { Colors } from '@styles';

import { Strings } from '@strings';

const FooterStoreButtonSection = () => {
  return (
    <Outer>
      <Container>
        <Head color={Colors.white}>{Strings.FOOTER_STORE_BUTTON_SECTION_HEADS[0]}</Head>
        <Head color={Colors.white} bold>
          {Strings.FOOTER_STORE_BUTTON_SECTION_HEADS[1]}
        </Head>
        <Buttons>
          <StoreButton storeType={StoreType.APP_STORE} />
          <StoreButton storeType={StoreType.GOOGLE_PLAY} />
        </Buttons>
      </Container>
    </Outer>
  );
};

export default FooterStoreButtonSection;
