import { createContext } from 'react';

import { Language } from '@strings';

type LanguageContextProps = {
  language: Language;
  setLanguage: (language: Language) => void;
};

export const LanguageContext = createContext<LanguageContextProps>({
  language: Language.EN,
  setLanguage: () => {
    // * will be overwritten
  },
});
