import { Fragment, useContext, useState } from 'react';
import { Link, Routes, useLocation, useSearchParams } from 'react-router-dom';

import { NoDecoAnchor, NoDecoLink } from '@components';
import { HeaderMenu, LanguageSelector } from './components';

import { LanguageContext } from '@context';

import { NotionUrl, SearchParamNames, Urls } from '@utils';

import { Colors } from '@styles';
import { icon_menu, logo_text } from '@assets';
import {
  HeaderContainer,
  LogoText,
  HeaderButton,
  LogoTextContainer,
  Container,
  MenuButton,
  MenuIcon,
  Margin,
  Buttons,
} from './Header.styles';

import { Language, Strings } from '@strings';

const Header = () => {
  const [searchParams] = useSearchParams({
    [SearchParamNames.SEARCH_PARAM_APP_VIEW]: String(false),
  });
  const { language } = useContext(LanguageContext);
  const { pathname } = useLocation();

  const shouldHeaderShown = searchParams.get(SearchParamNames.SEARCH_PARAM_APP_VIEW) !== 'true';

  const [isMenuOpened, setMenuOpened] = useState(false);

  const toggleMenu = () => {
    setMenuOpened((state) => !state);
  };

  if (!shouldHeaderShown) return <Fragment />;
  return (
    <Fragment>
      <Container>
        <Routes>{/* <Route path={Urls.HOME} element={<CouponEventBanner />} /> */}</Routes>
        <HeaderContainer>
          <MenuButton onClick={toggleMenu}>
            <MenuIcon src={icon_menu} alt='menuIcon' />
          </MenuButton>
          <LogoTextContainer>
            <Link to={Urls.HOME}>
              <LogoText src={logo_text} alt={Strings.Common.DR_LISTEN} />
            </Link>
          </LogoTextContainer>
          <Buttons>
            <HeaderButton color={Colors.gray700} active={pathname === Urls.HOME}>
              <NoDecoLink to={Urls.HOME}>{Strings.HEADER_DR_LISTEN}</NoDecoLink>
            </HeaderButton>
            <HeaderButton color={Colors.gray700} active={pathname === Urls.ABOUT_SERVICE}>
              <NoDecoLink to={Urls.ABOUT_SERVICE}>{Strings.HEADER_SERVICE}</NoDecoLink>
            </HeaderButton>
            <HeaderButton color={Colors.gray700} active={pathname === Urls.CORE_VALUES}>
              <NoDecoLink to={Urls.CORE_VALUES}>{Strings.HEADER_VISION}</NoDecoLink>
            </HeaderButton>
            {language === Language.KO && (
              <HeaderButton color={Colors.gray700} active={pathname === Urls.COUPON}>
                <NoDecoLink to={Urls.COUPON}>{Strings.HEADER_MENU_COUPON_CODE}</NoDecoLink>
              </HeaderButton>
            )}
            {/* <HeaderButton color={Colors.gray700}>
              <NoDecoLink to={Urls.INVITATION}>{Strings.HEADER_MENU_INVITATION_EVENT}</NoDecoLink>
            </HeaderButton> */}
            <HeaderButton color={Colors.gray700}>
              <NoDecoAnchor href={NotionUrl.COMPANY_CONTACT} target='_blank'>
                {Strings.HEADER_MENU_CONTACT_COMPANY}
              </NoDecoAnchor>
            </HeaderButton>
            <LanguageSelector />
          </Buttons>
        </HeaderContainer>
      </Container>
      <HeaderMenu isOpened={isMenuOpened} onClick={() => setMenuOpened(false)} />
      <Margin />
    </Fragment>
  );
};

export default Header;
