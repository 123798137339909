const Support1029 = {
  SECTIONA_HEAD: [
    '2022년 10월 29일,',
    '고귀한 생명을 잃은 분들의\n명복을 빕니다.',
    '아울러,\n안타깝게 가족을 잃은 유족과\n현장에 계셨던 분들에게도\n위로의 마음을 전합니다.',
  ],

  SECTIONB_HEAD_REGULAR: '2022년 가을\n유난히도 슬픈 시간을\n보내야 했던 대한민국',
  SECTIONB_HEAD_BOLD: '우리는 아픔을 느끼는 정도나\n시기가 다를 수 있습니다.',
  SECTIONB_DESCRIPTION: '외상 후 초기 3개월 상태 확인 및 치료가 중요합니다.',

  SECTIONC_HEAD_1: '전 국민 누구나 간편하게\n정신건강을 확인할 수 있도록',
  SECTIONC_HEAD_2: '닥터리슨이 위로의 마음을 담아',
  SECTIONC_HEAD_3_BOLD: '정신건강 무제한 자가평가\n3개월 이용권을 제공해 드립니다.',

  SECTIOND_NEW_USER: '신규회원',
  SECTIOND_HOW_TO_GET_PRODUCT: '이용권 지원 받는 방법',
  SECTIOND_HEAD_REGULAR: '지원 기간 내\n닥터리슨 APP을 다운로드하고',
  SECTIOND_HEAD_BOLD: '회원가입 후 로그인하세요.',
  // * 1029 DATE
  SECTIOND_DESCRIPTION:
    '아래 기간 내 신규 회원 가입하신 분들에게 제공됩니다.\n2022년 11월 16일(수) ~ 2022년 12월 31일(토)',

  SECTIONE_OLD_USER: '기존회원',
  SECTIONE_HOW_TO_GET_PRODUCT: '이용권 지원 받는 방법',
  // * 1029 DATE
  SECTIONE_REGULAR: '11월 16일 이전에 가입하신 분들은',
  SECTIONE_BOLD: '이용권 신청하기 버튼을 클릭하고\n정보를 입력해 주세요.',
  SECTIONE_DESCRIPTION:
    '1단계: 이용권 신청하기 버튼\n2단계: 닥터리슨에 가입된 이메일 정보 입력 후 제출',
  SECTIONE_APPLY: '이용권 신청하기',

  SECTIONE_NOTICE_HEAD: '정신건강 자가평가 무제한 이용권 안내',
  SECTIONE_NOTICE_KEYS: ['지원 대상', '발급 기간', '이용 기간'],
  SECTIONE_NOTICE_VALUES: [
    '닥터리슨 기존 회원 및 지원 기간 내 신규 가입자',
    // * 1029 DATE
    '2022년 11월 16일 (수) ~ 2022년 12월 31일 (토)',
    '이용권 지급일로부터 90일',
  ],
  SECTIONE_CONTACT_HEAD: '확인해주세요!',
  SECTIONE_CONTACT_BODY: '문의사항이 있다면 이야기해 주세요.',
  SECTIONE_CONTACT_KEYS: ['전화', '어플'],
  SECTIONE_CONTACT_VALUES: [
    '고객센터 02-6402-0118\n평일 오전 10시 ~ 오후 7시',
    '[닥터리슨 APP → 설정 →  문의하기]',
  ],
  SECTIONE_CONTACT_FOOTER: '본 지원은 당사의 사정으로 변경 또는 조기종료될 수 있습니다',

  SECTIONF_HEAD: ['빠르게 지원가능하도록 ', '닥터리슨이 최선의 노력을 다하겠습니다.'],
  SECTIONF_BODY_1: ['12월 초', '<c>외상 후 스트레스 장애(PTSD) 검사</c>가', '추가될 예정입니다.'],
  SECTIONF_BODY_2: [
    '치료 및 상담이 ',
    '필요하신 분을 위한',
    '<c>병· 의원, 지원센터 정보</c> 제공도',
    '업데이트 예정입니다.',
  ],

  BANNER_TEXT: '닥터리슨이 <c>전국민 정신건강을 응원합니다.</c>',
};

export default Support1029;
