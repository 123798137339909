import { useContext } from 'react';

import {
  DescriptionText,
  HeadText,
  AccentText,
  Container,
  ImageContainer,
  Outer,
  PhoneImage,
  TextContainer,
} from './ReportSection.styles';

import { LanguageContext } from '@context';

import { report_section_phone, report_section_phone_eng } from '@assets';

import { Language, Strings } from '@strings';

const ReportSection = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Outer>
      <Container>
        <ImageContainer>
          {language === Language.KO && (
            <PhoneImage src={report_section_phone} alt='report_section_phone' />
          )}
          {language === Language.EN && (
            <PhoneImage src={report_section_phone_eng} alt='report_section_phone_eng' />
          )}
        </ImageContainer>
        <TextContainer>
          <AccentText bold>{Strings.SERVICE_REPORT_SECTION_ACCENT}</AccentText>
          <HeadText marginBottom={6}>{Strings.SERVICE_REPORT_SECTION_HEADS[0]}</HeadText>
          <HeadText bold>{Strings.SERVICE_REPORT_SECTION_HEADS[1]}</HeadText>
          <DescriptionText>{Strings.SERVICE_REPORT_SECTION_DESCRIPTION}</DescriptionText>
        </TextContainer>
      </Container>
    </Outer>
  );
};

export default ReportSection;
