import { Fragment } from 'react';
import { NoticeSection, UsageSection, MainImageSection, UsageGuideSection } from './components';

const Coupon = () => {
  return (
    <Fragment>
      <MainImageSection />
      <UsageGuideSection />
      <UsageSection />
      <NoticeSection />
    </Fragment>
  );
};

export default Coupon;
