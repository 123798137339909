const Common = {
  Footer: {
    ADDRESS: '서울특별시 중구 명동길 73 페이지명동 5층 (우)04538',
    BUSINESS_REGI_NUMBER: '사업자등록번호 421-88-00471',
    COPYRIGHT: '© Genesis Lab, Inc.  All rights reserved.',
    Terms: {
      SERVICE: '서비스 이용약관',
      PRIVACY: '개인정보 처리방침',
    },
  },
  DR_LISTEN: '닥터리슨',
  APP_STORE: 'App Store',
  GOOGLE_PLAY: 'Google Play',
};

export default Common;
