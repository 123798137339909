import styled from 'styled-components';

import { Text } from '@components/Text/Text';
import { CommonContainer, CommonOuter } from '../common';

import { Colors, Flex, Media } from '@styles';

export const Outer = styled(CommonOuter)`
  background-color: ${Colors.blue50};
`;

export const Container = styled(CommonContainer)`
  ${Flex.Row};

  padding: 79px 0px;

  ${Media.mobile} {
    ${Flex.Column};
    align-items: flex-start;

    padding: 60px 30px;
  }
`;

export const ImageContainer = styled.div`
  margin-top: 69px;
`;

export const CharacterImage = styled.img`
  width: 522px;
  height: 492px;

  ${Media.mobile} {
    width: 100%;
    max-width: 362px;
    height: auto;
  }
`;

export const TextContainer = styled.div`
  ${Flex.Column};
`;

type HeadTextProps = {
  enableMarginBottomInMobile?: boolean;
};
export const HeadText = styled(Text.H2)<HeadTextProps>`
  color: ${Colors.black};

  ${Media.mobile} {
    margin-bottom: ${(props) => (props.enableMarginBottomInMobile ? 6 : 0)}px;

    font-size: 24px;
    line-height: 29px;
    letter-spacing: -2px;
  }
`;
