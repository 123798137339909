import styled from 'styled-components';

import { MAX_WIDTH_MOBILE } from '@utils';

import { Colors } from '@styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 800px;

  background-color: ${Colors.white};

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: block;

    height: auto;

    padding: 70px 30px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 1170px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    align-items: flex-start;
    flex-wrap: wrap-reverse;

    width: auto;
    row-gap: 130px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 20px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    row-gap: 16px;
  }
`;

export const PuzzleImg = styled.img`
  width: 294px;
  height: 309px;

  margin-right: 66px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    margin: 0 calc(100% - 138px);

    width: 138px;
    height: 145px;
  }
`;
