import { ArrowButton, ArrowImage, Container, Dot, Dots } from './PaginationBar.styles';

import { icon_arrow_right_gray500 } from '@assets';

type PaginationBarProps = {
  pageIndex: number;
  pageCount: number;

  onClickPrev: () => void;
  onClickNext: () => void;
};
const PaginationBar = (props: PaginationBarProps) => {
  const { pageIndex, pageCount, onClickPrev, onClickNext } = props;

  return (
    <Container>
      <ArrowButton direction='left' onClick={onClickPrev}>
        <ArrowImage src={icon_arrow_right_gray500} alt='left_arrow' />
      </ArrowButton>
      <Dots>
        {Array.from({ length: pageCount }).map((_, index) => (
          <Dot key={index} active={pageIndex === index} />
        ))}
      </Dots>
      <ArrowButton direction='right' onClick={onClickNext}>
        <ArrowImage src={icon_arrow_right_gray500} alt='right_arrow' />
      </ArrowButton>
    </Container>
  );
};

export default PaginationBar;
