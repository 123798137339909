export const Vision = {
  VISION_FIRST_STEP_SECTION_HEAD: '마음건강을 위한 첫걸음, 닥터리슨',
  VISION_FIRST_STEP_SECTION_BODIES: [
    '우리의 마음건강은 신체건강만큼 중요합니다.',
    '마음건강 관리 여정의 시작.\n나 자신의 상태를 명확하게 알 때,\n비로소 올바른 솔루션과 방향을 찾아 갈 수 있습니다.',
    '닥터리슨은 전인적(Holistic)이고\n명확한 마음건강 평가와 맞춤형 케어 가이드를 통해\n사람들의 삶의 질을 향상시키고자 합니다.',
  ],

  VISION_PIE_CHART_SECTION_CHART_DESCRIPTION: '국내 정신질환유병자 의료이용 현황',
  VISION_PIE_CHART_SECTION_ACCENT: '조기인지 및 개입 필요',
  VISION_PIE_CHART_SECTION_HEADS: ['여전히 많은 사람들이', '정신건강 사각지대에\n놓여있습니다.'],
  VISION_PIE_CHART_SECTION_DESCRIPTION:
    '보건복지부에서 발표한\n제2차정신건강복지기본계획(2021~2025)에 따르면\n우리나라 정신질환유병자 중 48.7%가\n정신질환임을 인지하지 못하고 방치되고 있습니다.',

  VISION_SOLUTION_SECTION_HEADS: [
    '닥터리슨은 명확한 상태를 확인하고',
    '상태마다 적합한 솔루션을 제안합니다',
  ],

  VISION_ACCURACY_SECTION_HEADS: ['전인적 평가로', '정확함을 추구합니다.'],
  VISION_ACCURACY_SECTION_DESCRIPTION:
    '마음건강과 밀접한 다양한 요소(신체, 사회, 생활습관 등)를\n전인적으로 모두 고려하여 구성되었습니다.\n닥터리슨은 단편적인 증상 확인이 아닌,\n정신건강 영역에서도 다각도의 평가를 통해\n종합적으로 분석하고 명확하게 안내해드립니다.',
  VISION_ACCURACY_SECTION_NOTICE: '*마음건강 영역 평가 도구는 지속적으로 업데이트 예정입니다.',
  VISION_ACCURACY_SECTION_MENTAL: '정신적',
  VISION_ACCURACY_SECTION_PHYSICAL: '신체적',
  VISION_ACCURACY_SECTION_SOCIAL: '사회적',
  VISION_ACCURACY_SECTION_HOLISTIC: '전인적',

  VISION_KEYWORD_SECTION_KEYWORDS: ['정확성', '신뢰', '공감'],
  VISION_KEYWORD_SECTION_KEYWORDS_ENG: ['Accuracy', 'Trust', 'Empathy'],
  VISION_KEYWORD_SECTION_HEADS: ['언제나 이용자를', '최우선으로 생각합니다'],
  VISION_KEYWORD_SECTION_DESCRIPTION:
    '정확성, 신뢰, 공감 3가지 핵심가치를 기반으로\n이용자의 삶의 질을 높일 수 있는\n디지털 멘탈 헬스 서비스가 되고자 합니다.',

  VISION_CHEERING_SECTION_HEADS: ['닥터리슨은', '언제나 여러분의\n마음건강을 응원합니다'],
};
