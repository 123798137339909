import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Home, Coupon, Invitation, Share, Service, Vision } from '@pages';
import { Header, Loading, Modal, Footer, ScrollToTop } from '@components';

import { useViewTypeUpdater } from './hooks';

import { LanguageContext } from './context';

import { LanguageStorage, setStorage, Urls } from '@utils';

import { getInitLanguage, Language, Strings } from '@strings';

function App() {
  useViewTypeUpdater();

  const [language, setLanguage] = useState(Language.EN);

  useEffect(() => {
    setAllLanguageConfig(getInitLanguage());
  }, []);

  const setAllLanguageConfig = (language: Language) => {
    Strings.setLanguage(language);
    setLanguage(language);
    document.documentElement.lang = language;
    setStorage<LanguageStorage>('language', language);
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage: setAllLanguageConfig,
      }}
    >
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path={Urls.HOME} element={<Home />} />
          <Route path={Urls.COUPON} element={<Coupon />} />
          <Route path={Urls.INVITATION} element={<Invitation />} />
          <Route path={Urls.SHARE} element={<Share />} />
          <Route path={Urls.ABOUT_SERVICE} element={<Service />} />
          <Route path={Urls.CORE_VALUES} element={<Vision />} />
        </Routes>
        <Footer />
        <Modal />
        <Loading />
      </BrowserRouter>
    </LanguageContext.Provider>
  );
}

export default App;
