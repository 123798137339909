import styled from 'styled-components';

import { Text } from '@components/Text/Text';
import { CommonContainer, CommonOuter } from '../common';

import { Colors, Media } from '@styles';

export const Outer = styled(CommonOuter)`
  background-color: ${Colors.blue50};
`;

export const Container = styled(CommonContainer)`
  flex-direction: column;

  padding: 144.5px 73px;

  ${Media.mobile} {
    padding: 60px 49px;
  }
`;

export const Title = styled(Text.H2)`
  text-align: center;

  white-space: nowrap;

  ${Media.mobile} {
    white-space: pre-wrap;
  }
`;

export const Reviews = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  column-gap: 17px;
  margin-top: 40px;
  margin-bottom: 40px;
`;
