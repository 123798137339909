export const Vision = {
  VISION_FIRST_STEP_SECTION_HEAD: 'Dr. Listen, the first step to mental health',
  VISION_FIRST_STEP_SECTION_BODIES: [
    'Our mental health is just as important as our physical health.',
    'The beginning of your mental health journey.\nWhen you have a clear picture of your condition,\nyou can find the right solutions and direction.',
    'We provide holistic mental health assessments\nand personalized care guidance.',
  ],

  VISION_PIE_CHART_SECTION_CHART_DESCRIPTION:
    'Healthcare Utilization by People\nwith Mental Illness in Korea',
  VISION_PIE_CHART_SECTION_ACCENT: 'Early recognition and intervention needs',
  VISION_PIE_CHART_SECTION_HEADS: ['Many people are', 'still in mental health\nblind spots.'],
  VISION_PIE_CHART_SECTION_DESCRIPTION:
    'According to\nthe 2nd Mental Health Welfare Basic Plan (2021~2025)\nreleased by the Ministry of Health and Welfare\n48.7% of people with mental illness\nin Korea are left unrecognized and untreated.',

  VISION_SOLUTION_SECTION_HEADS: [
    'Dr. Listen identifies clear conditions',
    'and suggest the right solution for each condition',
  ],

  VISION_ACCURACY_SECTION_HEADS: ['Global Assessment', 'Striving for accuracy'],
  VISION_ACCURACY_SECTION_DESCRIPTION:
    'A holistic approach to mental health\nthat takes into account the various factors (physical, social, lifestyle, etc.)\nthat are closely related to mental health.\nRather than checking symptoms alone,\nDr. Listen provides a multifaceted assessment in the area of mental health\nwith comprehensive analysis and clear guidance.',
  VISION_ACCURACY_SECTION_NOTICE:
    '*The Mental Health Domain Assessment Tool will be updated on an ongoing basis.',
  VISION_ACCURACY_SECTION_MENTAL: 'Mental',
  VISION_ACCURACY_SECTION_PHYSICAL: 'Physical',
  VISION_ACCURACY_SECTION_SOCIAL: 'Social',
  VISION_ACCURACY_SECTION_HOLISTIC: 'Holistic',

  VISION_KEYWORD_SECTION_KEYWORDS: ['Accuracy', 'Trust', 'Empathy'],
  VISION_KEYWORD_SECTION_KEYWORDS_ENG: ['Accuracy', 'Trust', 'Empathy'],
  VISION_KEYWORD_SECTION_HEADS: ['Users are always', 'our top priority'],
  VISION_KEYWORD_SECTION_DESCRIPTION:
    'Based on our three core values\nof accuracy, trust, and empathy.\nwe aim to become a digital mental health service\nto improve the quality of life of users.',

  VISION_CHEERING_SECTION_HEADS: ['Dr. Listen is <c>supporting</c>', 'your mental health journey.'],
};
