import { Fragment } from 'react';
import {
  BannerSection,
  AboutServiceSection,
  UsageGuideSection,
  DownloadSection,
  NoticeSection,
} from './components';

const Invitation = () => {
  return (
    <Fragment>
      <BannerSection />
      <AboutServiceSection />
      <UsageGuideSection />
      <DownloadSection />
      <NoticeSection />
    </Fragment>
  );
};

export default Invitation;
