const Invitation = {
  Banner: {
    HEAD: ['초대 링크를 통해 회원 가입 하면', '친구와 나에게 무료 이용권 쿠폰을 드려요'],
    INVITATION_CODE: '닥터리슨 초대코드',
  },
  AboutService: {
    TITLE: '닥터리슨 서비스 소개',
    BODY: [
      '닥터리슨은 서울대 병원 정신 건강 의학과와 함께 설계한 <c>정신 건강 전문의 수준의 AI</c>입니다.',
      '나의 상태에 가장 적합한 맞춤형 상담으로 정신 질환에 대한 정확한 분석을 해 드립니다.',
    ],
  },
  UsageGuide: {
    TITLE: '초대 코드 입력 안내',
    GUIDES: [
      '친구로부터 온 메세지에 담긴 초대 코드를 회원 가입 시 입력',
      '회원가입 완료 후 상담 가능한 무료 쿠폰을 이메일로 발송',
    ],
  },
  Download: {
    TITLE: '앱 다운로드',
    GO_TO_STORE: '닥터리슨 바로가기',
  },
  Notice: {
    TITLE: '친구 초대 안내 사항',
    BODY: [
      '초대받은 분이 초대 코드를 입력한 후 초대한 분과 초대받은 분 모두에게 상담 이용권 쿠폰이 이메일로 발송됩니다.',
      '친구 초대로 받은 쿠폰은 로그인 후 사용할 수 있습니다.',
      '초대 코드를 이미 등록한 이력이 있는 경우, 재등록이 불가능합니다.',
      '초대 코드 보내기는 무제한으로 가능합니다.',
      '쿠폰 사용을 목적으로 한 회원 탈퇴/재가입/사행성 행위 등의 비정상적인 경로 적발시 쿠폰이 회수되며, 추후 이벤트 제외 등의 불이익이 있을 수 있습니다.',
      '친구 초대 이벤트는 회사의 상황에 따라 사전 공지 없이 변경되거나 종료될 수 있습니다.',
    ],
  },
};

export default Invitation;
