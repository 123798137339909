import { useContext } from 'react';

import {
  Container,
  H2WithMarginBottom,
  H4WithMargin,
  InnerContainer,
  MockUpPhoneImage,
  Outer,
  ServiceLinkButton,
  ServiceLinkButtonMobile,
} from './AboutServiceSection.styles';

import { LanguageContext } from '@context';

import { Urls } from '@utils';

import { about_service_section_phone, about_service_section_phone_eng } from '@assets';

import { Language, Strings } from '@strings';

const AboutServiceSection = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Outer>
      <Container>
        <InnerContainer>
          {language === Language.KO && (
            <MockUpPhoneImage src={about_service_section_phone} alt='about_service_section_phone' />
          )}
          {language === Language.EN && (
            <MockUpPhoneImage
              src={about_service_section_phone_eng}
              alt='about_service_section_phone_eng'
            />
          )}
          <ServiceLinkButtonMobile to={Urls.ABOUT_SERVICE}>
            {Strings.ABOUT_SERVICE_SECTION_BUTTON}
          </ServiceLinkButtonMobile>
        </InnerContainer>
        <InnerContainer>
          <H4WithMargin accent bold>
            {Strings.ABOUT_SERVICE_SECTION_ACCENT}
          </H4WithMargin>
          <H2WithMarginBottom marginBottom={6}>
            {Strings.ABOUT_SERVICE_SECTION_HEADS[0]}
          </H2WithMarginBottom>
          <H2WithMarginBottom marginBottom={20} strong>
            {Strings.ABOUT_SERVICE_SECTION_HEADS[1]}
          </H2WithMarginBottom>
          <ServiceLinkButton to={Urls.ABOUT_SERVICE}>
            {Strings.ABOUT_SERVICE_SECTION_BUTTON}
          </ServiceLinkButton>
        </InnerContainer>
      </Container>
    </Outer>
  );
};

export default AboutServiceSection;
