import { useEffect } from 'react';

import { AppScheme, getOSType, isMobile, StoreType, StoreUrl } from '@utils';

const Share = () => {
  useEffect(() => {
    const osType = getOSType();

    const storeType = osType === 'ios' ? StoreType.APP_STORE : StoreType.GOOGLE_PLAY;

    if (!isMobile) {
      window.location.href = StoreUrl[storeType];
      return;
    }

    window.location.href = AppScheme[storeType === StoreType.APP_STORE ? 'IOS' : 'ANDROID'];

    setTimeout(() => {
      if (!(document.webkitHidden || document.hidden)) {
        window.location.href = StoreUrl[storeType];
      }
    }, 100);
  }, []);

  return <></>;
};

export default Share;
