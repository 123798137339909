import { HTMLAttributes } from 'react';
import { InnerContainer, InnerContainerProps, OuterContainer } from './ListenContainer.styles';

interface ContainerProps extends HTMLAttributes<HTMLDivElement>, InnerContainerProps {}

const ListenContainer = (props: ContainerProps) => {
  return (
    <OuterContainer>
      <InnerContainer {...props} />
    </OuterContainer>
  );
};

export default ListenContainer;
