import styled from 'styled-components';

import { Text } from '@components/Text/Text';

import { Colors, FlexRow, Media } from '@styles';

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  width: 330px;
  height: 330px;
  padding: 20px;
  border-radius: 15px;

  background-color: ${Colors.white};
`;

export const Stars = styled.div`
  ${FlexRow};

  height: 22px;
  column-gap: 4px;

  margin-bottom: 20px;
`;

export const Star = styled.img`
  width: 18px;
  height: 18px;
`;

export const Title = styled(Text.H3)`
  margin-bottom: 20px;

  ${Media.mobile} {
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -2px;
  }
`;

export const Content = styled(Text.Body1)`
  flex: 1;
`;

export const NickName = styled(Text.Body2)`
  color: ${Colors.gray500};
`;
