import styled from 'styled-components';

import { Text } from '@components/Text/Text';
import { CommonContainer, CommonOuter } from '../common';

import { Colors, Flex, Fonts, Media } from '@styles';

export const Outer = styled(CommonOuter)`
  background-color: ${Colors.blue50};
`;

export const Container = styled(CommonContainer)`
  padding: 93px 73px;

  ${Media.mobile} {
    flex-direction: column;

    padding: 60px 30px;
  }
`;

export const TextContainer = styled.div`
  ${Flex.Column};
`;

type HeadTextProps = {
  enableMarginBottom?: boolean;
};
export const HeadText = styled(Text.H2)<HeadTextProps>`
  margin-bottom: ${(props) => (props.enableMarginBottom ? 6 : 0)}px;
`;

export const DescriptionText = styled(Text.H4)`
  margin-top: 20px;
  margin-bottom: 20px;

  color: ${Colors.gray500};
`;

export const NoticeText = styled(Text.Body2)`
  color: ${Colors.gray500};
`;

export const ImageContainer = styled.div`
  position: relative;

  ${Media.mobile} {
    margin-top: 71px;
  }
`;

export const VennDiagramImage = styled.img`
  width: 449.23px;

  ${Media.mobile} {
    width: auto;
    height: 338.51px;
  }
`;

const VennDiagramText = styled(Text.H3)`
  position: absolute;

  ${Fonts.Bold};

  color: ${Colors.blue400};
`;

export const MentalText = styled(VennDiagramText)`
  top: 90px;
  left: 50%;
  transform: translateX(-50%);

  ${Media.mobile} {
    top: 73px;
  }
`;

export const PhysicalText = styled(VennDiagramText)`
  top: 261px;
  left: 77px;

  ${Media.mobile} {
    top: 214px;
    left: 63px;
  }
`;

export const SocialText = styled(VennDiagramText)`
  top: 261px;
  right: 83px;

  ${Media.mobile} {
    top: 214px;
    right: 68.5px;
  }
`;

export const HolisticText = styled(VennDiagramText)`
  top: 213.23px;
  left: 50%;
  transform: translateX(-50%);

  ${Media.mobile} {
    top: 175px;
  }
`;
