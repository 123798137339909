import styled from 'styled-components';
import { Colors, Fonts } from '@styles';
import { MAX_WIDTH_MOBILE } from '@utils';

export const Container = styled.section`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    margin-top: 40px;
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: 50px;
`;

export const UsageGuideImages = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    flex-direction: column;
  }
`;

export const GuideImageContainer = styled.div`
  width: 233px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    row-gap: 0px;
  }
`;

export const GuideImage = styled.img`
  width: 110px;
  height: 110px;
  margin-bottom: 20px;
`;

export const NumberLabel = styled.div`
  ${Fonts.body1};
  ${Fonts.Bold};
  color: ${Colors.blue400};
  height: 28px;
  width: 28px;
  background-color: ${Colors.blue80};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`;

export const GuideDescription = styled.span`
  ${Fonts.body1};
  ${Fonts.Bold};
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
`;

export const RightArrow = styled.img`
  width: 30px;
  height: 30px;
  margin: 0px 30px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    margin: 10px 0px;
    transform: rotate(90deg);
  }
`;
