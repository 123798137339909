import styled from 'styled-components';

import { Text } from '@components/Text/Text';
import { CommonContainer, CommonOuter } from '../common';

import { Colors, FlexColumn, FlexRow, Media } from '@styles';

export const Outer = styled(CommonOuter)`
  background: linear-gradient(92.93deg, #62a7fb 35.45%, #3c7ae5 66.95%);
`;

export const Container = styled(CommonContainer)`
  ${FlexColumn};
  align-items: center;

  padding-top: 155px;
  padding-bottom: 148px;

  ${Media.mobile} {
    padding: 60px 30px;
  }
`;

export const SubTitle = styled(Text.H3)`
  margin-bottom: 20px;

  white-space: normal;
  text-align: center;

  color: ${Colors.white};

  ${Media.mobile} {
    margin-bottom: 6px;

    font-size: 24px;
    line-height: 29px;

    white-space: pre-wrap;
  }
`;

export const Title = styled(Text.H2)`
  white-space: normal;
  text-align: center;

  color: ${Colors.white};

  ${Media.mobile} {
    margin-bottom: 40px;

    font-size: 24px;
    line-height: 29px;

    white-space: pre-wrap;
  }
`;

export const StepRow = styled.div`
  ${FlexRow};
  align-items: center;
  flex-wrap: wrap;

  margin-top: 63px;

  ${Media.mobile} {
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
    row-gap: 16px;
    margin-top: 16px;
  }
`;
