import { Fragment } from 'react';
// components
import { TitleWithUnderline } from '@pages/home/components';
import { NoDecoAnchor } from '@components';
import {
  Buttons,
  Container,
  MobileContainer,
  MobileDownloadButton,
  StoreButton,
  StoreIcon,
} from './DownloadSection.styles';
// utils
import { StoreUrl } from '@utils';
// strings
import { Strings } from '@strings';
// styles
import { Colors } from '@styles';
// assets
import { icon_app_store, icon_google_play } from '@assets';

const DownloadSection = () => {
  const onClickMobileDownload = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const iphoneRegExp = /iphone/g;
    const androidRegExp = /android/g;
    const windowRegExp = /win16|win32|win64/g;
    const macRegExp = /mac|macintel/g;

    if (iphoneRegExp.test(userAgent)) {
      window.open(StoreUrl.APP_STORE);
      return;
    }
    if (androidRegExp.test(userAgent)) {
      window.open(StoreUrl.GOOGLE_PLAY);
      return;
    }
    if (windowRegExp.test(userAgent)) {
      window.open(StoreUrl.GOOGLE_PLAY);
      return;
    }
    if (macRegExp.test(userAgent)) {
      window.open(StoreUrl.APP_STORE);
      return;
    }
  };

  return (
    <Fragment>
      <Container>
        <TitleWithUnderline color={Colors.white}>
          {Strings.Invitation.Download.TITLE}
        </TitleWithUnderline>
        <Buttons>
          <NoDecoAnchor href={StoreUrl.APP_STORE} target='_blank'>
            <StoreButton>
              <StoreIcon src={icon_app_store} alt='appStoreIcon' />
              {Strings.Common.APP_STORE}
            </StoreButton>
          </NoDecoAnchor>
          <NoDecoAnchor href={StoreUrl.GOOGLE_PLAY} target='_blank'>
            <StoreButton>
              <StoreIcon src={icon_google_play} alt='googlePlayIcon' /> {Strings.Common.GOOGLE_PLAY}
            </StoreButton>
          </NoDecoAnchor>
        </Buttons>
      </Container>
      <MobileContainer>
        <MobileDownloadButton onClick={onClickMobileDownload}>
          {Strings.Invitation.Download.GO_TO_STORE}
        </MobileDownloadButton>
      </MobileContainer>
    </Fragment>
  );
};

export default DownloadSection;
