import { Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Body, Button, Container, DimmedBackground, Title } from './Modal.styles';
import { CommonActions, RootState } from '@store';

const Modal = () => {
  const { modal } = useSelector((state: RootState) => state.CommonReducer);
  const dispatch = useDispatch();

  const { body, buttonText, isShown, onClick, title } = modal;

  const containerRef = useRef<HTMLDivElement>(null);

  const onClickButton = () => {
    dispatch(CommonActions.setModal({ isShown: false }));
    onClick && onClick();
  };

  if (!isShown) return <Fragment />;
  return (
    <DimmedBackground>
      <Container ref={containerRef}>
        <Title>{title}</Title>
        <Body>{body}</Body>
        <Button onClick={onClickButton}>{buttonText}</Button>
      </Container>
    </DimmedBackground>
  );
};

export default Modal;
