import { CSSStyles } from '@interfaces';
import styled from 'styled-components';

export const Container = styled.section`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TitleContainer = styled.div`
  margin-bottom: 40px;
`;

export const styles: CSSStyles = {
  emailInput: {
    marginBottom: '20px',
  },
  couponInput: {
    marginBottom: '40px',
  },
};
