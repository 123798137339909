import { Fragment, useContext } from 'react';

import {
  AccentText,
  Container,
  DescriptionText,
  HeadText,
  ImageContainer,
  Outer,
  PhoneImage,
  PhoneImageMobile,
  TextContainer,
} from './TrendSection.styles';

import { LanguageContext } from '@context';

import { trend_section_phone, trend_section_phone_eng, trend_section_phone_mobile } from '@assets';

import { Strings, Language } from '@strings';

const TrendSection = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Outer>
      <Container>
        <ImageContainer>
          {language === Language.KO && (
            <Fragment>
              <PhoneImage src={trend_section_phone} alt='trend_section_phone' />
              <PhoneImageMobile src={trend_section_phone_mobile} alt='trend_section_phone_mobile' />
            </Fragment>
          )}
          {language === Language.EN && (
            <Fragment>
              <PhoneImage src={trend_section_phone_eng} alt='trend_section_phone_eng' />
              <PhoneImageMobile src={trend_section_phone_eng} alt='trend_section_phone_eng' />
            </Fragment>
          )}
        </ImageContainer>
        <TextContainer>
          <AccentText bold>{Strings.SERVICE_TREND_SECTION_ACCENT}</AccentText>
          <HeadText marginBottom={6}>{Strings.SERVICE_TREND_SECTION_HEADS[0]}</HeadText>
          <HeadText bold>{Strings.SERVICE_TREND_SECTION_HEADS[1]}</HeadText>
          <DescriptionText>{Strings.SERVICE_TREND_SECTION_DESCRIPTION}</DescriptionText>
        </TextContainer>
      </Container>
    </Outer>
  );
};

export default TrendSection;
