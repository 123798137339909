export const Header = {
  HEADER_DR_LISTEN: 'Dr.Listen',
  HEADER_SERVICE: 'Service',
  HEADER_VISION: 'Core values',
  HEADER_MENU_COUPON_CODE: 'Enter coupon code',
  HEADER_MENU_CONTACT_COMPANY: 'For Businesses',
  HEADER_LANGUAGE_SELECTOR_KOR: 'KOR',
  HEADER_LANGUAGE_SELECTOR_ENG: 'ENG',

  // * unused
  HEADER_SHARE: '공유하기',
  HEADER_MENU_INVITATION_EVENT: '친구 초대 이벤트',
};
