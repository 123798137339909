export const Home = {
  HOME_STORE_BUTTON_SECTION_HEAD3:
    'Developed with a psychiatrist at Seoul National University Hospital',
  HOME_STORE_BUTTON_SECTION_HEAD1: ['The first step to mental health,', 'Join Dr. Listen.'],

  DO_NOT_WORRY_SECTION_HEADS: ['Do you feel lost?', 'Do you feel stuck on your own?'],
  DO_NOT_WORRY_SECTION_CIRCLE_TEXTS: [
    'I feel exhausted all the time.\nWhat can I do about it?',
    'Why does everything\nfeel so draining?',
    'I feel like a disappointment.\nHow can I improve my life,\nand finally be happy again?',
  ],
  DO_NOT_WORRY_SECTION_FOOTERS: [
    'Stop worrying on you own,',
    'and check your mental health on Dr. Listen',
  ],
  DO_NOT_WORRY_SECTION_FOOTERS_MOBILE: [
    'Stop worrying on you own,',
    'and check your mental health',
    'on Dr. Listen',
  ],

  EASY_USE_SECTION_ACCENT: 'Easy and convenient to use anytime, anywhere.',
  EASY_USE_SECTION_HEADS: ['Easily inspect on your phone,', 'and analyze your results\nin one go!'],

  HOLISTIC_ASSESSMENT_SECTION_DIAGRAM_MENTAL: 'Mental',
  HOLISTIC_ASSESSMENT_SECTION_DIAGRAM_PHYSICAL: 'Physical',
  HOLISTIC_ASSESSMENT_SECTION_DIAGRAM_SOCIAL: 'Social',
  HOLISTIC_ASSESSMENT_SECTION_DIAGRAM_HOLISTIC: 'Holistic',

  HOLISTIC_ASSESSMENT_SECTION_ACCENT: "It's not just a depression screening tool.",
  HOLISTIC_ASSESSMENT_SECTION_HEADS: [
    "It's not just about mental health",
    'It is a comprehensive view\nof your lifestyle',
  ],
  HOLISTIC_ASSESSMENT_SECTION_BODY:
    'The first, and most important, step in your mental health journey is\nto have a clear picture of your condition.\nWe use a holistic self-assessment that takes into account mental,\nphysical, and social factors to give you a clear picture of your\ncurrent state.',

  COLLABORATIVE_DEVELOPMENT_SECTION_ACCENT: 'Created with a mental health expert.',
  COLLABORATIVE_DEVELOPMENT_SECTION_HEADS: [
    'Seoul National\nUniversity Hospital',
    'Mental Health Physician\nCo-development',
  ],
  COLLABORATIVE_DEVELOPMENT_SECTION_CIRCLE_TEXT: '20+ Years\nClinical\nexperience',

  ABOUT_SERVICE_SECTION_ACCENT: 'Mental health AI self-assessment and management services',
  ABOUT_SERVICE_SECTION_HEADS: [
    'Get a clear picture of\nyour current mental health',
    'and find the right solutions',
  ],
  ABOUT_SERVICE_SECTION_BUTTON: 'Learn more',

  REVIEW_SECTION_HEAD: 'Check out what people are saying about Dr. Listen.',
  REVIEW_CARD_SCORES: [5, 4, 5, 4, 5, 5],
  REVIEW_CARD_TITLES: [
    'An app that you need to try out!',
    'An app that you need!',
    'I like being able to see\nthe changes in my state .',
    "I didn't know if I was bipolar\nor depressed!",
    'This was very helpful.',
    "Very good,\nI'm going to use this a lot",
  ],
  REVIEW_CARD_CONTENTS: [
    "Many people don't realize how crucial it is to understand their own condition.\nI recommend this app to all the people in our country who are just trying to get through the day. Just knowing your condition can be the beginning of improvement.",
    "An acquaintance recommended it to me.\nI usually don't think I have any problems, but...! I felt embarrassed about the results, because I did not think of it, but it was helpful. I hope many people will use this kind of good service.",
    "I've been feeling a little down lately, and whenever I'm feeling down, I've been checking my state. I'm hoping that my condition will improve next year . The app is updated frequently and I think it's getting better.",
    "I've been tested for depression/bipolar disorder... I've been feeling more irritable than usual lately and work hasn't been going well. I think now I know why... There is also an app lock and it seems to be reliable.",
    'I have a lot of stressful things going on, and it helped me to see things objectively. It is often inconvenient to schedule a counseling appointment as I always happen to forget.',
    'On this app, you get to be more honest with yourself.  Your mental health is important for your future. I think it would be good to manage mental health with an app like this.',
  ],
  REVIEW_CARD_NICKNAMES: ['', '', '', '', '', ''],

  CORE_VISION_SECTION_ACCENT: 'We want to improve the quality of life for all of us.',
  CORE_VISION_SECTION_HEADS: ['Dr. Listen’s', 'Core Values'],
  CORE_VISION_SECTION_BUTTON: 'Learn more',

  FOOTER_STORE_BUTTON_SECTION_HEADS: [
    'Your mental health is complex\nand difficult to understand.',
    'Try Dr. Listen today.',
  ],

  INVITATION_BANNER_TITLE:
    '초대 링크를 통해 회원가입하면 <c>친구와 나에게 무료 이용권 쿠폰</c>을 드려요',
  INVITATION_BANNER_TITLE_MOBILE: '초대 링크를 통해 회원가입하면 <c>무료 이용권 쿠폰</c>을 드려요',
  INVITATION_BANNER_DO_NOT_SHOW_TODAY: '오늘 하루 보지 않기',
} as const;
