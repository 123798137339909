import { useContext } from 'react';

import {
  ChartImage,
  Container,
  HeadText,
  AccentText,
  DescriptionText,
  PieChartContainer,
  Outer,
  TextContainer,
  ChartDescriptionTexts,
} from './PieChartSection.styles';

import { LanguageContext } from '@context';

import { pie_chart_section_chart, pie_chart_section_chart_eng } from '@assets';

import { Language, Strings } from '@strings';

const PieChartSection = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Outer>
      <Container>
        <PieChartContainer>
          {language === Language.KO && (
            <ChartImage src={pie_chart_section_chart} alt='pie_chart_section_chart' />
          )}
          {language === Language.EN && (
            <ChartImage src={pie_chart_section_chart_eng} alt='pie_chart_section_chart' />
          )}
          <ChartDescriptionTexts>
            {Strings.VISION_PIE_CHART_SECTION_CHART_DESCRIPTION}
          </ChartDescriptionTexts>
        </PieChartContainer>
        <TextContainer>
          <AccentText>{Strings.VISION_PIE_CHART_SECTION_ACCENT}</AccentText>
          <HeadText marginBottom={6}>{Strings.VISION_PIE_CHART_SECTION_HEADS[0]}</HeadText>
          <HeadText bold>{Strings.VISION_PIE_CHART_SECTION_HEADS[1]}</HeadText>
          <DescriptionText>{Strings.VISION_PIE_CHART_SECTION_DESCRIPTION}</DescriptionText>
        </TextContainer>
      </Container>
    </Outer>
  );
};

export default PieChartSection;
