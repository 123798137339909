const MODAL = {
  Coupon: {
    COUPON_USE_RESULTS: {
      SUCCESS: {
        title: '쿠폰이 정상적으로 사용되었어요.',
        body: '쿠폰이 이용권으로 변경 되었어요.\n이용권은 앱 내 설정 페이지에서 확인 가능해요.',
        buttonText: '닫기',
      },
      NO_EMAIL: {
        title: '이메일을 확인할 수 없어요.',
        body: '이메일을 다시 확인하거나,\n신규 회원 가입 후 쿠폰을 사용해 주세요.',
        buttonText: '닫기',
      },
      REMAINED_SERVICE_PRODUCT_TO_USE: {
        title: '앱 내 이용권을 모두 소진한 후\n쿠폰을 사용해 주세요.',
        body: '사용중인 이용권이 있으면\n쿠폰 사용이 불가해요.',
        buttonText: '닫기',
      },
      COMPANY_MEMBER_EMAIL: {
        title: '기업 회원은 쿠폰을 사용할 수 없어요.',
        body: '쿠폰 사용을 원한다면 개인 회원으로 가입해 주세요.',
        buttonText: '닫기',
      },
      EXPIRED: {
        title: '쿠폰 사용 기간이 만료되었어요.',
        body: '쿠폰 관련 문의 사항이 있다면\ndr.listen@genesislab.ai로 문의해 주세요.',
        buttonText: '닫기',
      },
      ALREADY_USED: {
        title: '이미 사용된 쿠폰이에요.',
        body: '쿠폰 관련 문의 사항이 있다면\ndr.listen@genesislab.ai로 문의해 주세요.',
        buttonText: '닫기',
      },
      ALREADY_USED_SAME_COUPON_TYPE: {
        title: '이전에 동일한 프로모션\n쿠폰을 이미 사용했어요.',
        body: '쿠폰 관련 문의 사항이 있다면\ndr.listen@genesislab.ai로 문의해 주세요.',
        buttonText: '닫기',
      },
    },
  },
};

export default MODAL;
