import {
  BodyTexts,
  Container,
  HeadText,
  BodyText,
  ImageContainer,
  Outer,
  CompassImage,
  TextContainer,
} from './FirstStepSection.styles';

import { first_step_section_compass } from '@assets';

import { Strings } from '@strings';

const FirstStepSection = () => {
  const bodyTextsCount = Strings.VISION_FIRST_STEP_SECTION_BODIES.length;

  return (
    <Outer>
      <Container>
        <TextContainer>
          <HeadText bold>{Strings.VISION_FIRST_STEP_SECTION_HEAD}</HeadText>
          <BodyTexts>
            {Array.from({ length: bodyTextsCount }).map((_, index) => (
              <BodyText key={index}>{Strings.VISION_FIRST_STEP_SECTION_BODIES[index]}</BodyText>
            ))}
          </BodyTexts>
        </TextContainer>
        <ImageContainer>
          <CompassImage src={first_step_section_compass} alt='first_step_section_compass' />
        </ImageContainer>
      </Container>
    </Outer>
  );
};

export default FirstStepSection;
