import styled from 'styled-components';

import { MAX_WIDTH_MOBILE } from '@utils';

import { bg_gray } from '@assets';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 800px;

  background-image: url(${bg_gray});

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: block;

    height: 763px;

    padding-top: 70px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 1170px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    position: relative;
    flex-direction: column;
    align-items: flex-start;

    width: auto;
    row-gap: 130px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 20px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    margin-left: 30px;
    row-gap: 10px;
  }
`;

export const FlowerImg = styled.img`
  width: 426px;
  height: 355px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    transform: rotate(-15deg);
    position: absolute;
    right: -50px;
    top: 400px;

    width: 320px;
    height: auto;
  }
`;
