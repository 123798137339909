import styled from 'styled-components';

import { Text } from '@components/Text/Text';
import { CommonContainer, CommonLinkButton, CommonOuter } from '../common';

import { Media, Colors, FlexColumn } from '@styles';

export const Outer = styled(CommonOuter)`
  background-color: ${Colors.white};
`;

export const Container = styled(CommonContainer)`
  padding: 150px 73px;

  ${Media.mobile} {
    flex-direction: column-reverse;
    align-items: flex-start;

    padding: 60px 30px;
  }
`;

export const InnerContainer = styled.div`
  ${FlexColumn};
`;

export const TextContainer = styled(InnerContainer)`
  /* margin-right: 60px; */
  width: 410px;

  ${Media.mobile} {
    width: auto;
  }
`;

export const PeopleImage = styled.img`
  width: 444px;
  height: 300px;
  margin-left: 135px;

  ${Media.mobile} {
    margin-left: 0px;

    width: 100%;
    height: auto;
    margin-top: ${32 - 20}px;
  }
`;

export const H4WithMargin = styled(Text.H4)`
  margin-bottom: 20px;
`;

type H2WithMarginProps = {
  marginBottom: number;
};
export const H2WithMargin = styled(Text.H2)<H2WithMarginProps>`
  margin-bottom: ${(props) => props.marginBottom}px; ;
`;

export const VisionLinkButton = styled(CommonLinkButton)`
  ${Media.mobile} {
    display: none;
  }
`;

export const VisionLinkButtonMobile = styled(CommonLinkButton)`
  display: none;
  ${Media.mobile} {
    display: inherit;

    width: 100%;
    margin-top: 32px;
  }
`;
