import { Container, Outer, Reviews, Title } from './ReviewSection.styles';

import { PaginationBar, ReviewCard } from './components';

import { useCommonSlice } from '@hooks';

import { ViewType } from '@store';

import { Strings } from '@strings';
import { useEffect, useState } from 'react';

const PAGINATION_COUNT = {
  [ViewType.DESKTOP]: 3,
  [ViewType.MOBILE]: 1,
};

const ReviewSection = () => {
  const { viewType } = useCommonSlice();

  const [pageIndex, setPageIndex] = useState(0);

  const reviewPerPage = PAGINATION_COUNT[viewType];
  const totalReviewCount = Strings.REVIEW_CARD_TITLES.length;
  const pageCount = totalReviewCount / reviewPerPage;
  const reviewStartIndex = pageIndex * reviewPerPage;

  useEffect(() => {
    setPageIndex(0);
  }, [viewType, setPageIndex]);

  const setValidPageIndex = (newPageIndex: number) => {
    if (newPageIndex < 0 || newPageIndex >= pageCount) {
      return;
    }

    setPageIndex(newPageIndex);
  };

  return (
    <Outer>
      <Container>
        <Title>{Strings.REVIEW_SECTION_HEAD}</Title>
        <Reviews>
          {Array.from({ length: reviewPerPage }).map((_, index) => (
            <ReviewCard
              key={index}
              score={Strings.REVIEW_CARD_SCORES[index + reviewStartIndex]}
              title={Strings.REVIEW_CARD_TITLES[index + reviewStartIndex]}
              content={Strings.REVIEW_CARD_CONTENTS[index + reviewStartIndex]}
              nickname={Strings.REVIEW_CARD_NICKNAMES[index + reviewStartIndex]}
            />
          ))}
        </Reviews>
        <PaginationBar
          pageIndex={pageIndex}
          pageCount={pageCount}
          onClickPrev={() => setValidPageIndex(pageIndex - 1)}
          onClickNext={() => setValidPageIndex(pageIndex + 1)}
        />
      </Container>
    </Outer>
  );
};

export default ReviewSection;
