import { Fragment } from 'react';

import {
  SelfAssessmentSection,
  ManagementSection,
  SecuritySection,
  ReportSection,
  NoteSection,
  TrendSection,
  EncryptionSection,
} from './components';

const Service = () => {
  return (
    <Fragment>
      <ManagementSection />
      <SecuritySection />
      <SelfAssessmentSection />
      <ReportSection />
      <NoteSection />
      <TrendSection />
      <EncryptionSection />
    </Fragment>
  );
};

export default Service;
