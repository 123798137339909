import { Responsive } from '../styles/Responsive';

export const mapWithCount = (count: number, callback: (idx: number) => any) => {
  return new Array(count).fill(null).map((each, idx) => callback(idx));
};

export const checkIsMobileResponsiveView = () =>
  window.matchMedia(`(${Responsive.MOBILE})`).matches;

const checkIfIsMobile = () =>
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i)
    ? true
    : false;

export const isMobile = checkIfIsMobile();

export const getOSType = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.indexOf('android') > -1) {
    return 'android';
  } else if (
    userAgent.indexOf('iphone') > -1 ||
    userAgent.indexOf('macintosh') > -1 ||
    userAgent.indexOf('ios') > -1 ||
    userAgent.indexOf('ipad') > -1
  ) {
    return 'ios';
  }
  return 'android';
};
