import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';

import { NoDecoAnchor, NoDecoLink, Text } from '@components';

// import { RootState } from '@store';
import { LanguageContext } from '@context';

import { NotionUrl, Urls } from '@utils';

import { Colors } from '@styles';
import { Container, MenuButton } from './HeaderMenu.styles';

import { Language, Strings } from '@strings';
import { default as LanguageSelector } from '../LanguageSelector/LanguageSelector';

interface HeaderMenuProps {
  isOpened: boolean;
  onClick: () => void;
}
const HeaderMenu = (props: HeaderMenuProps) => {
  const { isOpened, onClick } = props;
  const { pathname } = useLocation();
  const { language } = useContext(LanguageContext);

  // const { eventBanner } = useSelector((state: RootState) => state.CommonReducer);

  // const isEventBannerShown = eventBanner.isShown && location.pathname === Urls.HOME;
  const isEventBannerShown = false;

  const isActiveButton = (url: string) => {
    return url === pathname;
  };

  const getTextProps = (url: string) => {
    if (isActiveButton(url)) {
      return {
        bold: true,
        color: Colors.blue400,
      };
    }
    return {
      medium: true,
      color: Colors.gray700,
    };
  };

  return (
    <Container isOpened={isOpened} isEventBannerShown={isEventBannerShown} onClick={onClick}>
      <NoDecoLink to={Urls.HOME}>
        <MenuButton>
          <Text.Body2 {...getTextProps(Urls.HOME)}>{Strings.HEADER_DR_LISTEN}</Text.Body2>
        </MenuButton>
      </NoDecoLink>
      <NoDecoLink to={Urls.ABOUT_SERVICE}>
        <MenuButton>
          <Text.Body2 {...getTextProps(Urls.ABOUT_SERVICE)}>{Strings.HEADER_SERVICE}</Text.Body2>
        </MenuButton>
      </NoDecoLink>
      <NoDecoLink to={Urls.CORE_VALUES}>
        <MenuButton>
          <Text.Body2 {...getTextProps(Urls.CORE_VALUES)}>{Strings.HEADER_VISION}</Text.Body2>
        </MenuButton>
      </NoDecoLink>
      {language === Language.KO && (
        <NoDecoLink to={Urls.COUPON}>
          <MenuButton>
            <Text.Body2 {...getTextProps(Urls.COUPON)}>
              {Strings.HEADER_MENU_COUPON_CODE}
            </Text.Body2>
          </MenuButton>
        </NoDecoLink>
      )}
      {/* <MenuButton>
        <NoDecoLink to={Urls.INVITATION}>
          <Text.Body2 {...getTextProps(Urls.INVITATION)}>
            {Strings.Common.Header.Menu.INVITATION_EVENT}
          </Text.Body2>
        </NoDecoLink>
      </MenuButton> */}
      <NoDecoAnchor href={NotionUrl.COMPANY_CONTACT} target='_blank'>
        <MenuButton>
          <Text.Body2 color={Colors.gray700} medium>
            {Strings.HEADER_MENU_CONTACT_COMPANY}
          </Text.Body2>
        </MenuButton>
      </NoDecoAnchor>
      <MenuButton>
        <LanguageSelector forceDisplayInMobile={true} />
      </MenuButton>
    </Container>
  );
};

export default HeaderMenu;
