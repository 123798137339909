import styled, { css } from 'styled-components';

import { Text } from '@components/Text/Text';
import { CommonContainer, CommonOuter } from '../common';

import { Colors, Flex, Fonts, Media } from '@styles';
import { vision_keyword_section_circles } from '@assets';

export const Outer = styled(CommonOuter)`
  background-color: ${Colors.white};
`;

export const Container = styled(CommonContainer)`
  ${Flex.Row};

  padding: 189px 0px;

  ${Media.mobile} {
    flex-direction: column-reverse;

    padding: 60px 30px;
  }
`;

export const ImageContainer = styled.div``;

export const KeywordBackgroundImage = styled.div`
  ${Flex.Row};
  align-items: center;
  justify-content: space-around;

  width: 558px;
  height: 206px;
  padding: 0px 12px;

  background: url(${vision_keyword_section_circles});
  background-size: contain;

  ${Media.mobile} {
    width: 367.96px;
    height: 135.84px;
    padding: 0px 9px;
    margin-top: 61px;
  }
`;

export const KeywordTextContainer = styled.div`
  flex: 1;

  ${Flex.Column};
  justify-content: center;
  align-items: center;
`;

type KeywordTextProps = {
  small?: boolean;
};
export const KeywordText = styled(Text.H2)<KeywordTextProps>`
  ${Fonts.Bold};

  color: ${Colors.white};

  ${(props) =>
    props.small &&
    css`
      font-size: 35px;
      line-height: 42px;
      letter-spacing: -2.5px;
    `}

  ${Media.mobile} {
    font-size: 29px;
    line-height: 35px;
    letter-spacing: -1.65px;

    ${(props) =>
      props.small &&
      css`
        font-size: 25px;
        line-height: 30px;
        letter-spacing: -1.65px;
      `}
  }
`;

export const KeywordLabel = styled(Text.H4)`
  ${Fonts.Bold};

  color: ${Colors.white};

  margin-top: -7px;
`;

export const TextContainer = styled.div`
  ${Flex.Column};

  ${Media.mobile} {
    align-self: flex-start;
  }
`;

type HeadTextProps = {
  enableMarginBottom?: boolean;
};
export const HeadText = styled(Text.H2)<HeadTextProps>`
  color: ${Colors.black};

  ${Media.mobile} {
    margin-bottom: ${(props) => (props.enableMarginBottom ? 6 : 0)}px;

    font-size: 24px;
    line-height: 29px;
    letter-spacing: -2px;
  }
`;

export const DescriptionText = styled(Text.H4)`
  margin-top: 20px;

  color: ${Colors.gray500};

  ${Media.mobile} {
    margin-top: 16px;
  }
`;
