import { Fragment, useContext } from 'react';

import {
  TextContainer,
  ImageContainer,
  Container,
  Outer,
  AccentText,
  HeadText,
  DescriptionText,
  PhoneImage,
  Chips,
} from './SelfAssessmentSection.styles';
import { Chip } from './components';

import { LanguageContext } from '@context';

import { self_assessment_section_phone, self_assessment_section_phone_eng } from '@assets';
import { Colors } from '@styles';

import { Language, Strings } from '@strings';

const SelfAssessmentSection = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Outer>
      <Container>
        <TextContainer>
          <AccentText bold color={Colors.blue400}>
            {Strings.SERVICE_SELF_ASSESSMENT_SECTION_ACCENT}
          </AccentText>
          <HeadText marginBottom={6}>{Strings.SERVICE_SELF_ASSESSMENT_SECTION_HEADS[0]}</HeadText>
          <HeadText bold>{Strings.SERVICE_SELF_ASSESSMENT_SECTION_HEADS[1]}</HeadText>
          <DescriptionText color={Colors.gray500}>
            {Strings.SERVICE_SELF_ASSESSMENT_SECTION_DESCRIPTION}
          </DescriptionText>
          {language === Language.KO && (
            <Fragment>
              <Chips marginBottom>
                <Chip>{Strings.SERVICE_SELF_ASSESSMENT_SECTION_LABELS[0]}</Chip>
                <Chip>{Strings.SERVICE_SELF_ASSESSMENT_SECTION_LABELS[1]}</Chip>
                <Chip>{Strings.SERVICE_SELF_ASSESSMENT_SECTION_LABELS[2]}</Chip>
              </Chips>
              <Chips>
                <Chip>{Strings.SERVICE_SELF_ASSESSMENT_SECTION_LABELS[3]}</Chip>
                <Chip>{Strings.SERVICE_SELF_ASSESSMENT_SECTION_LABELS[4]}</Chip>
              </Chips>
            </Fragment>
          )}
          {language === Language.EN && (
            <Fragment>
              <Chips marginBottom>
                <Chip>{Strings.SERVICE_SELF_ASSESSMENT_SECTION_LABELS[0]}</Chip>
                <Chip>{Strings.SERVICE_SELF_ASSESSMENT_SECTION_LABELS[1]}</Chip>
              </Chips>
              <Chips marginBottom>
                <Chip>{Strings.SERVICE_SELF_ASSESSMENT_SECTION_LABELS[2]}</Chip>
              </Chips>
              <Chips>
                <Chip>{Strings.SERVICE_SELF_ASSESSMENT_SECTION_LABELS[3]}</Chip>
                <Chip>{Strings.SERVICE_SELF_ASSESSMENT_SECTION_LABELS[4]}</Chip>
              </Chips>
            </Fragment>
          )}
        </TextContainer>
        <ImageContainer>
          {language === Language.KO && (
            <PhoneImage src={self_assessment_section_phone} alt='self_assessment_section_phone' />
          )}
          {language === Language.EN && (
            <PhoneImage
              src={self_assessment_section_phone_eng}
              alt='self_assessment_section_phone_eng'
            />
          )}
        </ImageContainer>
      </Container>
    </Outer>
  );
};

export default SelfAssessmentSection;
