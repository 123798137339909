import styled from 'styled-components';
// styles
import { Colors, Fonts, Responsive } from '@styles';

export const Container = styled.div`
  row-gap: 5px;

  display: flex;
  flex-direction: column;
`;

export interface TitleProps {
  color?: string;
}
export const Title = styled.span<TitleProps>`
  ${Fonts.Bold};

  color: ${(props) => props.color ?? Colors.gray800};

  font-size: 24px;
  line-height: 36px;
  letter-spacing: -1px;

  @media screen and (${Responsive.MOBILE}) {
    font-size: 22px;
    line-height: 33px;
  }
`;

export const Underline = styled.div`
  background-color: ${Colors.blue300};

  height: 5px;
  border-radius: 6px;
  width: 100%;
`;
