import styled, { CSSProperties } from 'styled-components';

import { Colors } from '@styles';
import { MAX_WIDTH_MOBILE } from '@utils';
import { Button, NoDecoAnchor } from '@components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 799px;

  background-color: ${Colors.gray80};

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: block;

    height: auto;
    padding: 70px 30px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 1170px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: block;

    width: auto;

    position: relative;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 56.5px 0px;

  row-gap: 20px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    padding: 0px;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  column-gap: 4px;
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 6px;
  border-radius: 6px;

  background-color: ${Colors.gray100};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResizedButton = styled(Button)`
  width: 270px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    width: 174px;
    height: 57px;

    border-radius: 10px;
  }
`;

export const ResizedNoDecoAnchor = styled(NoDecoAnchor)`
  width: 270px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    width: 174px;
  }
`;

export const buttonMarginStyle: CSSProperties = {
  marginTop: 20,
  marginBottom: 20,
};

export const ArrowIcon = styled.img`
  width: 20px;
  height: 20px;

  margin-left: 10px;
`;

export const ImgContainer = styled.div`
  display: flex;

  justify-content: flex-end;
`;

export const FormImg = styled.img`
  width: 294px;
  height: 290px;
  margin-top: 146px;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    width: 147px;
    height: auto;
    margin-top: 66px;
  }
`;
