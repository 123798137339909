import { API_ENDPOINT } from '@utils';

export enum CouponUseResult {
  // 쿠폰 적용 성공
  SUCCESS = 'SUCCESS',
  // 가입된 이메일을 확인할 수 없음
  NO_EMAIL = 'NO_EMAIL',
  // 아예 없는 쿠폰 ID 를 입력한 경우
  NO_COUPON = 'NO_COUPON',
  // 사용 가능한 이용권이 남아 있는 경우
  REMAINED_SERVICE_PRODUCT_TO_USE = 'REMAINED_SERVICE_PRODUCT_TO_USE',
  // 기업 회원 이메일인 경우
  COMPANY_MEMBER_EMAIL = 'COMPANY_MEMBER_EMAIL',
  // 기간 만료인 쿠폰인 경우
  EXPIRED = 'EXPIRED',
  // 이미 사용된 쿠폰인 경우
  ALREADY_USED = 'ALREADY_USED',
  // 이미 동일한 종류의 쿠폰이 사용된 경우
  ALREADY_USED_SAME_COUPON_TYPE = 'ALREADY_USED_SAME_COUPON_TYPE',
}

export interface FetchUseCouponResult {
  data?: {
    useCoupon: CouponUseResult;
  };
  errors?: {
    extensions: {
      code: string;
      exception: {
        stacktrace: string[];
      };
    };
  }[];
}

const generateUseCouponDocument = (email: string, couponId: string) => {
  return `mutation {
    useCoupon(email: "${email}", couponId: "${couponId}")
  }`;
};

export const fetchUseCoupon = async (email: string, couponId: string) => {
  const useCouponDocument = generateUseCouponDocument(email, couponId);

  const response = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query: useCouponDocument }),
  });

  const result = await response.json();
  return result as FetchUseCouponResult;
};
