export const Service = {
  SERVICE_MANAGEMENT_SECTION_SUB_TITLE:
    'Your mental health is just as important as your physical health.',
  SERVICE_MANAGEMENT_SECTION_TITLE: 'Check and manage with Dr. Listen',
  SERVICE_MANAGEMENT_SECTION_STEP_TITLE: [
    'Get started',
    'Self-assessment',
    'Results',
    'Record your thoughts',
    'Inspect and manage\nregularly',
  ],
  SERVICE_MANAGEMENT_SECTION_STEP_DESCRIPTION: [
    'Easily sign up\nand set a password',
    'Take a personalized\nquestionnaire to check\nyour mental health',
    'Check your mental\nhealth status,\ncare tips, and solutions',
    'Record your feelings, status\nand keep a gratitude journal',
    'Check-in regularly\nto see how your mental\nhealth is changing',
  ],

  SERVICE_SECURITY_SECTION_ACCENT: 'Simple and secure - anytime and anywhere.',
  SERVICE_SECURITY_SECTION_HEADS: [
    'User security is\nour top priority.',
    'Your information\nis securely stored.',
  ],
  SERVICE_SECURITY_SECTION_DESCRIPTION:
    'Dr. Listen is anonymous and provides secure\npasscode-lock to keep your information safe.',

  SERVICE_SELF_ASSESSMENT_SECTION_ACCENT: 'Well-organized & well-built',
  SERVICE_SELF_ASSESSMENT_SECTION_HEADS: [
    'Based on your status',
    'Personalized\nAI self-assessment',
  ],
  SERVICE_SELF_ASSESSMENT_SECTION_DESCRIPTION:
    'It consists of assessment questions\nthat you can answer based on your answers,\nfrom mental health to lifestyle.',
  SERVICE_SELF_ASSESSMENT_SECTION_LABELS: [
    '#Depression',
    '#Bipolar',
    '#PremenstrualDysphoricDisorder',
    '#Self-harmAndSuicide',
    '#Lifestyle',
  ],

  SERVICE_REPORT_SECTION_ACCENT: 'Quickly check with AI analytics',
  SERVICE_REPORT_SECTION_HEADS: ['About my mental health', 'Detailed analytics report'],
  SERVICE_REPORT_SECTION_DESCRIPTION:
    'Quickly check your current condition and symptoms\nin an easy and detailed way.',

  SERVICE_NOTE_SECTION_ACCENT: 'Based on research to improve mental health',
  SERVICE_NOTE_SECTION_HEADS: ['Quick and easy', 'Life Journaling features'],
  SERVICE_NOTE_SECTION_DESCRIPTION:
    'Provide record guides based on\nresearch to improve mental health.\nRecord and manage your feelings and state of mind.',

  SERVICE_TREND_SECTION_ACCENT: 'Periodic self-assessment of ',
  SERVICE_TREND_SECTION_HEADS: [
    'How your mental health\nis changing',
    'Verification and\nongoing management',
  ],
  SERVICE_TREND_SECTION_DESCRIPTION:
    'See change based on cumulative data, and not fragmented data.\nThis is making it easier to see changes and ongoing management.',

  SERVICE_ENCRYPTION_SECTION_ACCENT: "We'll keep you safe",
  SERVICE_ENCRYPTION_SECTION_HEADS: [
    'All usage data is\nstored are',
    'encrypted\nand can not be identified',
  ],
  SERVICE_ENCRYPTION_SECTION_DESCRIPTION: 'All results and records are confidential and anonymous.',
} as const;
