import styled from 'styled-components';
import { Colors, Fonts } from '@styles';
import { MAX_WIDTH_MOBILE } from '@utils';

interface StyledButtonProps {
  styleDisabled?: boolean;
}
export const StyledButton = styled.button<StyledButtonProps>`
  ${Fonts.Bold};
  ${Fonts.head4};
  height: 67px;
  width: 500px;

  border: none;
  background-color: ${(props) => (props.styleDisabled ? Colors.gray300 : Colors.blue400)};

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px 20px;
  color: ${Colors.white};
  border-radius: 6px;
  cursor: pointer;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    width: 335px;
    padding: 0px 15px;
  }
`;
