import styled from 'styled-components';
// styles
import { Colors, Fonts, Responsive } from '@styles';

export const Container = styled.section`
  height: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${Colors.blue400};

  @media screen and (${Responsive.MOBILE}) {
    height: 302px;
  }
`;

export const HeadContainer = styled.div`
  margin-bottom: 50px;
  text-align: center;

  @media screen and (${Responsive.MOBILE}) {
    margin-bottom: 30px;
  }
`;

export const Head = styled.h3`
  ${Fonts.Regular};

  color: ${Colors.white};

  font-size: 40px;
  line-height: 50.08px;
  letter-spacing: -1px;

  @media screen and (${Responsive.MOBILE}) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const BoldHead = styled.h2`
  ${Fonts.Bold};

  color: ${Colors.white};

  font-size: 44px;
  line-height: 55.09px;
  letter-spacing: -1px;

  @media screen and (${Responsive.MOBILE}) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const CouponCard = styled.div`
  background-color: ${Colors.blue300};

  padding: 31.53px;
  border: 1.58px solid ${Colors.blue200};
  border-radius: 20.4px;
  box-shadow: 0px 6.30509px 47.2881px rgba(0, 0, 0, 0.3);
  row-gap: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (${Responsive.MOBILE}) {
    border-radius: 13px;
    padding: 20px;
    row-gap: 6px;
  }
`;

export const CouponCardText = styled.span`
  ${Fonts.Regular}

  color: ${Colors.blue80};

  font-size: 25.2px;
  line-height: 37.8px;
  letter-spacing: -1px;

  @media screen and (${Responsive.MOBILE}) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.7px;
  }
`;

export const CouponCodeBoxRow = styled.div`
  column-gap: 9.46px;

  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (${Responsive.MOBILE}) {
    column-gap: 6px;
  }
`;

export const CouponCodeBox = styled.div`
  background-color: ${Colors.blue100};

  width: 34.7px;
  height: 34.7px;

  border-radius: 9.46px;

  @media screen and (${Responsive.MOBILE}) {
    width: 22px;
    height: 22px;
    border-radius: 6px;
  }
`;
