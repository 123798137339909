import { Colors, Fonts, Responsive } from '@styles';
import styled from 'styled-components';

export const Container = styled.section`
  background-color: ${Colors.gray80};

  width: 100vw;
`;

export const NoticeContainer = styled.div`
  width: 800px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 75px;
  row-gap: 20px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  @media screen and (${Responsive.MOBILE}) {
    width: 100vw;
    padding: 20px;
    row-gap: 10px;
  }
`;

export const NoticeIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Title = styled.h5`
  ${Fonts.Bold};
  color: ${Colors.gray800};

  font-size: 20px;
  letter-spacing: -1px;
  column-gap: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (${Responsive.MOBILE}) {
    font-size: 18px;
  }
`;

export const Li = styled.li`
  ${Fonts.Regular};
  color: ${Colors.gray700};

  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.7px;
  margin-bottom: 5px;

  @media screen and (${Responsive.MOBILE}) {
    color: ${Colors.gray600};

    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const Ul = styled.ul`
  margin: 0;
  padding-left: 1.5em;

  list-style: disc;

  & > ${Li}:last-child {
    margin-bottom: 0px;
  }
`;
