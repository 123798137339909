import styled from 'styled-components';
import { Fonts, Colors } from '@styles';
import { MAX_WIDTH_MOBILE } from '@utils';

export const Button = styled.button`
  ${Fonts.Medium};
  color: ${Colors.gray800};
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -1px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 170px;
  height: 64px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid ${Colors.white};

  column-gap: 10px;

  background-color: ${Colors.white};

  cursor: pointer;

  @media screen and (max-width: ${MAX_WIDTH_MOBILE}px) {
    font-size: 14px;
    line-height: 23px;

    width: 150px;
    height: 55px;
  }
`;

export const StoreIcon = styled.img`
  width: 24px;
  height: 24px;
`;
