import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
  isShown: boolean;
  title: string;
  body: string;
  onClick?: () => void;
  buttonText: string;
}

interface LoadingState {
  isShown: boolean;
}

interface EventBannerStates {
  isShown: boolean;
}

export enum ViewType {
  DESKTOP,
  MOBILE,
}

interface CommonStates {
  modal: ModalState;
  loading: LoadingState;
  eventBanner: EventBannerStates;
  viewType: ViewType;
}

const initialState: CommonStates = {
  modal: {
    isShown: false,
    title: '',
    body: '',
    onClick: () => null,
    buttonText: '',
  },
  loading: {
    isShown: true,
  },
  eventBanner: {
    isShown: true,
  },
  viewType: ViewType.DESKTOP,
};

const CommonSlice = createSlice({
  name: 'CommonSlice',
  initialState,
  reducers: {
    setModal: (state, action: PayloadAction<Partial<ModalState>>) => {
      state.modal = {
        ...initialState.modal,
        isShown: true,
        ...action.payload,
      };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading.isShown = action.payload;
    },
    setEventBannerShown: (state, action: PayloadAction<boolean>) => {
      state.eventBanner.isShown = action.payload;
    },
    setViewType: (state, action: PayloadAction<ViewType>) => {
      state.viewType = action.payload;
    },
  },
});

export const CommonActions = CommonSlice.actions;
export const CommonReducer = CommonSlice.reducer;
