import { Container, styles, TitleContainer } from './UsageSection.styles';
import { Button, Input } from '@components';
import { Strings } from '@strings';
import { useState } from 'react';
import { COUPON_CODE_LENGTH, isValidCouponId, isValidEmail } from '@utils';
import { CouponUseResult, fetchUseCoupon } from '@api';
import { useDispatch } from 'react-redux';
import { CommonActions } from '@store';
import { TitleWithUnderline } from '@pages/home/components';
import { useSearchParams } from 'react-router-dom';

const UsageSection = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams({
    email: '',
    coupon: '',
  });

  const [email, setEmail] = useState(searchParams.get('email') ?? '');
  const [couponId, setCouponId] = useState(searchParams.get('coupon') ?? '');

  const isButtonDisabled = !isValidEmail(email) || !isValidCouponId(couponId);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [couponCodeErrorMessage, setCouponCodeErrorMessage] = useState('');

  const onFocusEmail = () => setEmailErrorMessage('');
  const onFocusCouponCode = () => setCouponCodeErrorMessage('');

  const onChangeCouponCode = (value: string) => {
    if (value.length <= COUPON_CODE_LENGTH) {
      setCouponId(value.toUpperCase());
    }
  };

  const validateUseCoupon = (result: CouponUseResult) => {
    switch (result) {
      case CouponUseResult.NO_COUPON:
        setCouponCodeErrorMessage(Strings.Coupon.USAGE.NO_COUPON);
        break;
      case CouponUseResult.SUCCESS:
      case CouponUseResult.NO_EMAIL:
      case CouponUseResult.REMAINED_SERVICE_PRODUCT_TO_USE:
      case CouponUseResult.COMPANY_MEMBER_EMAIL:
      case CouponUseResult.EXPIRED:
      case CouponUseResult.ALREADY_USED:
      case CouponUseResult.ALREADY_USED_SAME_COUPON_TYPE:
        dispatch(CommonActions.setModal({ ...Strings.Modal.Coupon.COUPON_USE_RESULTS[result] }));
        break;
      default:
        setCouponCodeErrorMessage('');
        break;
    }
  };

  const onClickUseCoupon = async () => {
    if (!isValidEmail(email)) {
      setEmailErrorMessage(Strings.Coupon.USAGE.EMAIL_FORMAT_ERROR);
      return;
    }
    setEmailErrorMessage('');

    if (!isValidCouponId(couponId)) {
      setCouponCodeErrorMessage(Strings.Coupon.USAGE.COUPON_FORMAT_ERROR);
      return;
    }
    setCouponCodeErrorMessage('');

    const data = await fetchUseCoupon(email, couponId);
    if (data.errors) {
      setCouponCodeErrorMessage(Strings.Coupon.USAGE.UNKNOWN_ERROR);
      return;
    }

    if (data.data) {
      validateUseCoupon(data.data.useCoupon);
    }
  };

  return (
    <Container>
      <TitleContainer>
        <TitleWithUnderline>{Strings.Coupon.USAGE.TITLE}</TitleWithUnderline>
      </TitleContainer>
      <Input
        value={email}
        onChangeValue={setEmail}
        label={Strings.Coupon.USAGE.EMAIL_LABEL}
        placeholder={Strings.Coupon.USAGE.EMAIL_PLACEHOLDER}
        errorMessage={emailErrorMessage}
        onFocus={onFocusEmail}
        style={styles.emailInput}
      />
      <Input
        value={couponId}
        onChangeValue={onChangeCouponCode}
        label={Strings.Coupon.USAGE.COUPON_LABEL}
        placeholder={Strings.Coupon.USAGE.COUPON_PLACEHOLDER}
        errorMessage={couponCodeErrorMessage}
        onFocus={onFocusCouponCode}
        style={styles.couponInput}
      />
      <Button onClick={onClickUseCoupon} styleDisabled={isButtonDisabled}>
        {Strings.Coupon.USAGE.BUTTON}
      </Button>
    </Container>
  );
};

export default UsageSection;
