import { Fragment } from 'react';

import {
  StoreButtonSection,
  DoNotWorrySection,
  EasyUseSection,
  HolisticAssessmentSection,
  CollaborativeDevelopmentSection,
  AboutServiceSection,
  ReviewSection,
  CoreVisionSection,
  FooterStoreButtonSection,
} from './components';

const Home = () => {
  return (
    <Fragment>
      <StoreButtonSection />
      <DoNotWorrySection />
      <EasyUseSection />
      <HolisticAssessmentSection />
      <CollaborativeDevelopmentSection />
      <AboutServiceSection />
      <ReviewSection />
      <CoreVisionSection />
      <FooterStoreButtonSection />
    </Fragment>
  );
};

export default Home;
