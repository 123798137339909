// components
import { TitleWithUnderline } from '@pages/home/components';
import {
  ArrowContainer,
  ArrowImage,
  Container,
  Guide,
  GuideContainer,
  GuideDescription,
  GuideDescriptionRow,
  GuideImage,
  NumberLabel,
} from './UsageGuideSection.styles';
// strings
import { Strings } from '@strings';
// assets
import { icon_arrow_right_darker, invitation_usage_1, invitation_usage_2 } from '@assets';

const UsageGuideSection = () => {
  return (
    <Container>
      <TitleWithUnderline>{Strings.Invitation.UsageGuide.TITLE}</TitleWithUnderline>
      <GuideContainer>
        <Guide>
          <GuideImage src={invitation_usage_1} alt={'invitationUsageGuide1'} />
          <GuideDescriptionRow>
            <NumberLabel>1</NumberLabel>
            <GuideDescription>{Strings.Invitation.UsageGuide.GUIDES[0]}</GuideDescription>
          </GuideDescriptionRow>
        </Guide>
        <ArrowContainer>
          <ArrowImage src={icon_arrow_right_darker} alt={'arrowIcon'} />
        </ArrowContainer>
        <Guide>
          <GuideImage src={invitation_usage_2} alt={'invitationUsageGuide2'} />
          <GuideDescriptionRow>
            <NumberLabel>2</NumberLabel>
            <GuideDescription>{Strings.Invitation.UsageGuide.GUIDES[1]}</GuideDescription>
          </GuideDescriptionRow>
        </Guide>
      </GuideContainer>
    </Container>
  );
};

export default UsageGuideSection;
