import { Home } from './Home';
import { Service } from './Service';
import { Vision } from './Vision';

import { default as Coupon } from './Coupon';
import { default as Invitation } from './Invitation';
import { default as Support1029 } from './Support1029';
import { default as Share } from './Share';

export const PageStrings = {
  ...Home,
  ...Service,
  ...Vision,
  Coupon,
  Invitation,
  Support1029,
  Share,
};
